import react, { useState, useEffect } from "react";
import "../../../assets/css/style.css";
import { Col, Container, Row, Form } from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";
import { Router, Switch, Route, NavLink, Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import ReactLoading from "react-loading";
import DataTable from "react-data-table-component";
import Header from "../../Layout/Header";
import ToastHelper from "../Partial/toast";
import { ToastContainer } from 'react-toastify';
import NavBar from "../../Layout/NavBar";
import ImageUploader from "../Partial/SingleUpload";
import axios from "axios";
import Swal from "sweetalert2";
import toastr from "toastr";
import moment from 'moment';

let tempImageView = '';
const EVCharging = (props) => {
  const [show, setShow]                           = useState(false);
  const [showDetail, setShowDetail]               = useState(false);
  const [showUpdate, setShowUpdate]               = useState(false);
  const [showPricing, setShowPricing]             = useState(false);
  const [showPricingUpdate, setShowPricingUpdate] = useState(false);

  const [deleteId, setDeleteId]     = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showDeletePrice, setShowDeletePrice] = useState(false);
  const handleDeleteClose           = () =>{
    setShowDelete(false);
    setDeleteId('');
  } 
  const handleDeletePriceClose       = () =>{
    setShowDeletePrice(false);
    setDeleteId('');
  } 

  const handleDetailClose               = () => setShowDetail(false);
  const handleDetailShow                = () => setShowDetail(true);
  const handleCloseUpdate               = () => setShowUpdate(false);
  const handleShowUpdate                = () => setShowUpdate(true);
  const priceDetailClose                = () => setShowDetail(false);
  const priceDetailShow                 = () => setShowDetail(true);
  const priceCloseUpdate                = () => setShowPricingUpdate(false);
  const priceShowUpdate                 = () => setShowPricingUpdate(true);
  const handleClose                     = () => setShow(false);
  const handleShow                      = () => setShow(true);
  const handleClosePricing              = () => setShowPricing(false);
  const handleShowPricing               = () => setShowPricing(true);
  const authToken                       = localStorage.getItem("access_token");
  const [images, setImages]             = useState([]);
  const [logo, setLogo]                 = useState();
  const [imageUrl, setImageUrl]         = useState();
  const [name, setName]                 = useState();
  const [providerId, setProviderId]     = useState();
  const [visible, setVisible]           = useState(false);
  const [status, setStatus]             = useState();
  const [price, setPrice]               = useState();
  const [ac, setAc]                     = useState();  
  const [acPrice, setAcPrice]           = useState();
  const [dc, setDc]                     = useState();  
  const [dcPrice, setDcPrice]           = useState();
  const [singlePrice, setSinglePrice]   = useState();
  const [battery, setBattery]           = useState();
  const [speed, setSpeed]               = useState();
  const [date, setDate]                 = useState();
  const [isEdit, setIsEdit]             = useState(false);
  const [provider, setProvider]         = useState([]);
  const [loading, setLoading]           = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const MAX_LENGTH = 200;
  const [singleProvider, setSingleProvider] = useState("");


  function loadingProcess() {
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
  }

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };

  useEffect(() => {
    getProvider();
  }, []);

  //GET PROVIDER DATA ---------------------------------------------------
  const getProvider = (id = 0) => {
    
    let url = `${process.env.REACT_APP_API}/api/evprovider`;
    if (id != 0) {
      url = `${process.env.REACT_APP_API}/api/evprovider/${id}`;
      setLoadingPrice(true);
    } else {
      setLoading(true);
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        if (id != 0) {
          setSingleProvider(response.data.data);
          setIsEdit(response.data.data.id);
          setImageUrl(response.data.data.logo)
          setName(response.data.data.name);
          setStatus(response.data.data.status);
          setLoading(false);
          setLoadingPrice(false);
        } else {
          setProvider(response.data.data);
          setProviderId(response.data.data.id);
          setLoading(false);
          setLoadingPrice(false);
        }
        setLoading(false);
        setLoadingPrice(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setLoadingPrice(false);
      });
  };

   //GET PRICE DATA ---------------------------------------------------
   const getPricing = (id = 0) => {
    setLoadingPrice(true);
    let url = `${process.env.REACT_APP_API}/api/evprices`;
    if (id != 0) {
      url = `${process.env.REACT_APP_API}/api/evprices/${id}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        if (id != 0) {
          setSinglePrice(response.data.data);
          setAc(response.data.data.ev_ac);
          setProviderId(response.data.data.provider_id);
          setDc(response.data.data.ev_dc);
          setBattery(response.data.data.battery_capacity);
          setDate(response.data.data.pricing_date);
          setLoadingPrice(false);
        } else {
          setPrice(response.data.data);
          setLoadingPrice(false);
        }
        setLoadingPrice(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingPrice(false);
      });
  };

  const getPricingByProvider = (id) => {
    setLoadingPrice(true);
    let  url = `${process.env.REACT_APP_API}/api/evprices/getPriceByProvider/${id}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
          setPrice(response.data.data);
          setLoadingPrice(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingPrice(false);
      });
  };

  // CREATE PROVIDER ----------------------------------------------------
  const CreateProvider = () => {
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
      return;
    }
    setLoading(true);
    // var logo = [];
    // images.map((u) => logo.push(u.data_url));
    const payLoad = {
      name: name,
      status: status,
      logo: images,
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/evprovider`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        Swal.fire("Created!", "Provider has been created.", "success");
        setLoading(false);
        setShow(false);
        // console.log(response);
        getProvider();
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  // CREATE PRICING ----------------------------------------------------
  const CreatePricing = () => {
    setLoadingPrice(true);
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
          toastr.remove();
          toastr.options.positionClass = "toast-top-right";
          toastr.error("Fields are invalid!!");
        form[0].classList.add("was-validated");
      return;
    }
   
    const payLoad = {
      provider_id: providerId,
      ev_ac: ac,     
      ev_ac_cost: acPrice,
      ev_dc: dc,     
      ev_dc_cost: dcPrice,
      battery_capacity: battery,
      pricing_date: date,
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/evprices`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        Swal.fire("Created!", "Pricing has been created.", "success");
        setShow(false);
        // console.log(response);
        getProvider();
        setLoadingPrice(false);
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoadingPrice(false);
      });
  };

  // DELETE PROVIDER ----------------------------------------------------
  const deleteHandle = () => {
    if (deleteId == '') {
      return false;
    }
    const body = [`${deleteId}`];
    axios
      .delete(`${process.env.REACT_APP_API}/api/evprovider/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.code === 200) {
          getProvider();
          getPricing();
          handleDeleteClose();
          Swal.fire("Deleted!", "Provider has been deleted.", "success");    
        } else if (response.data.code !== 200) {
          toastr.remove();
          toastr.options.positionClass = "toast-top-right";
          toastr.error(response.data.message);
          setDeleteId('');
          handleDeleteClose();
        }
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
        handleDeleteClose();
      });
  };

   // DELETE PRICE ----------------------------------------------------
   const deleteHandlePrice = (id) => {
    if (deleteId == '') {
      return false;
    }
    const body = [`${deleteId}`];
    axios
      .delete(`${process.env.REACT_APP_API}/api/evprices/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.code === 200) {
          getPricingByProvider(providerId);
          handleDeletePriceClose();
          Swal.fire("Deleted!", "Price has been deleted.", "success");
        } else if (response.data.code !== 200) {
          toastr.remove();
          toastr.options.positionClass = "toast-top-right";
          toastr.error(response.data.message);
          handleDeletePriceClose();
        }
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
        handleDeletePriceClose();
      });
  };


  //UPDATE PROVIDER -----------------------------------------------------
  const Update = (id) => {
    // alert(images);
    if (!id) {
      return false;
    }
    setLoading(true);

    // var logo = [];
    // console.log("images",images);
    // images.map((u) => logo.push(u.data_url));
    
    const payLoad = {
      name: name,
      status: status,
      logo: images,
    };
    axios
      .put(`${process.env.REACT_APP_API}/api/evprovider/${id}`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        getProvider();
        handleCloseUpdate();
        Swal.fire("Updated!", "Provider has been Updated.", "success");
        // window.location.href = "/blog";
        setLoading(false);
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

   //UPDATE PRICE -----------------------------------------------------
   const updatePricing = (id) => {
    setLoadingPrice(true);
    if (!id) {
      return false;
    }
    const payLoad = {
        provider_id: providerId,
        ev_ac: ac,     
        ev_ac_cost: acPrice,
        ev_dc: dc,     
        ev_dc_cost: dcPrice,
        battery_capacity: battery,
        pricing_date: date,
    };
    axios
      .put(`${process.env.REACT_APP_API}/api/evprices/${id}`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        getPricingByProvider(providerId);
        handleCloseUpdate();
        Swal.fire("Updated!", "EV Price has been Updated.", "success");
        // window.location.href = "/blog";
        setLoadingPrice(false);
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoadingPrice(false);
      });
  };

  //UPDATE PROVIDER STATUS ----------------------------------------------
  const updateStatus = (id, status) => {
    if (!id) {
      return false;
    }
    const payLoad = {
      id: id,
      status: status,
    };
    axios
      .put(
        `${process.env.REACT_APP_API}/api/evprovider/updateStatus/${id}`,
        payLoad,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then(function (response) {
        getProvider();
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  // COLOUM FOR DATA TABLE
  const [columns] = useState([
    {
      name: "Provider",
      // width: "25%",
      minWidth:"120px",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <a className="datatab-link"
          onClick={() => {
            setProviderId(row.id);
            getProvider(row.id);
            getPricingByProvider(row.id);
            setVisible(true);
          }}
        >
          {row.name}
        </a>
      ),
    },
    {
      name: "Logo",
      // width: "25%",
      minWidth:"120px",
      cell: (row) => (
        <>
          <div className="partner-img">
            <img
              src={process.env.REACT_APP_API + "/" + row.logo}
              width="80px"
            />
          </div>
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // width: "20%",
      minWidth:"120px",
      sortable: true,
      cell: (row) => (
        <div>
          {row.status == 1 ? (
            <span
              class="text-success tog-switch"
              onClick={() => {
                updateStatus(row.id, row.status);
                getProvider();
              }}
            >
              <i class="fa fa-toggle-on text-success"></i>
            </span>
          ) : (
            <span
              class="text-success tog-switch"
              onClick={() => {
                updateStatus(row.id);
                getProvider();
              }}
            >
              <i class="fa fa-toggle-off text-danger"></i>
            </span>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      // width: "20%",
      minWidth:"120px",
      cell: (row) => (
        <div>
          <span
            onClick={() => {
              handleDetailShow();
              getProvider(row.id);
            }}
          >
            <i class="fa fa-eye text-secondary"></i>{" "}
          </span>{" "}
          &nbsp;&nbsp;
          <span
            onClick={() => {
              handleShowUpdate();
              loadingProcess();
              getProvider(row.id);
            }}
          >
            <i class="fa fa-edit text-primary"></i>{" "}
          </span>{" "}
          &nbsp;&nbsp;
          <span
  	        onClick={() => {
              setShowDelete(true);
              setDeleteId(row.id);
            }}
          >
            <i class="fa fa-trash-o text-danger"></i>{" "}
          </span>
        </div>
      ),
    },
  ]);

  // PRICINGCOLOUM FOR DATA TABLE
  const [columns_price] = useState([
    {
      name: "AC" ,
      width: "15%",
      selector: (row) => row.ev_ac,
      sortable: true,
      cell: (row) => <span>{row.ev_ac} KWH</span>,
    },
    {
        name: "AC Cost" ,
        width: "10%",
        selector: (row) => row.ev_ac,
        sortable: true,
        cell: (row) => <span>{row.ev_ac_cost} $</span>,
      },
    {
      name: "DC",
      width: "15%",
      cell: (row) => <span>{row.ev_dc} KWH</span>,
    },
    {
        name: "DC Cost",
        width: "10%",
        cell: (row) => <span>{row.ev_dc_cost} $</span>,
      },
      {
        name: "Speed",
        width: "10%",
        cell: (row) => <span>{row.speed}</span>,
      },
    {
      name: "Battery Capacity",
      selector: (row) => row.battery_capacity,
      width: "15%",
      sortable: true,
      cell: (row) => <span>{row.battery_capacity}</span>,
    },
    {
        name: "Pricing Date",
        selector: (row) => row.pricing_date,
        width: "10%",
        sortable: true,
        cell: (row) => <>{moment(row.pricing_date).format('LL')}</>,
      },
    {
      name: "Action",
      width: "10%",
      cell: (row) => (
        <div>
            <Link to={`/edit-price/${row.id}`}>
            <i class="fa fa-edit text-primary"></i>{" "}
          </Link>{" "}
          &nbsp;&nbsp;
          <span
  	        onClick={() => {
              setShowDeletePrice(true);
              setDeleteId(row.id);
            }}
          >
            <i class="fa fa-trash-o text-danger"></i>{" "}
          </span>
        </div>
      ),
    },
  ]);

  return (
    <div>
        <div class="wrapper">
          <div class="section">
            <div>
              <Header />
            </div>
            <div class="align-adj">
              <div>
                <Container>
                  <Row>
                    <Col xs={12} md={3} lg={3} sm={6}>
                      <button
                        type="button"
                        class="btn btn-info btn-partner"
                        onClick={() => {
                          loadingProcess();
                          handleShow();
                        }}
                      >
                        + Create New Provider
                      </button>
                    </Col>
                    <Col xs={12} md={3} lg={3} sm={6}>
                    <Link
                          type="button"
                          class="btn btn-info btn-create"
                          to = "/add-price"
                        >
                        + Add Price by Provider
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      <DataTable
                        title="Provider Lists"
                        defaultSortFieldId={1}
                        pagination
                        highlightOnHover
                        columns={columns}
                        data={provider}
                        progressPending={loading}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                        ignoreRowClick
                        selectableRows
                        dense
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 30, 50, 100]}
                      />
                    </Col>
                  </Row>
                  {visible == true ? (
                    <Row>
                      <Col className="mt-3">
                          
                        <DataTable
                          title={
                            loadingPrice ?
                            <ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />:
                          <>
                          <img
                            src={process.env.REACT_APP_API + "/" + singleProvider.logo}
                            width="80px"
                          />   &nbsp; &nbsp;
                          {singleProvider.name} 
                          </>}
                          defaultSortFieldId={1}
                          pagination
                          highlightOnHover
                          columns={columns_price}
                          data={price}
                          progressPending={loadingPrice}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                          ignoreRowClick
                        //   selectableRows
                          dense
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10, 30, 50, 100]}
                        />
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Container>

                {/* Add Modal Start */}
                <Modal show={show} 
                 onHide={() => {
                  handleClose();
                  tempImageView = '';
                }} >
                  <Modal.Header closeButton>
                    <Modal.Title>Create New Provider</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div class="modal-body">
                      <Form className="needs-validation create-form" autocomplete="off" noValidate>
                        <div className="create-form">
                          <div>
                            <label class="labelForm">Logo:</label>
                            <ImageUploader setImageHandler={setImageHandler} />
                            {tempImageView != ''? <img src={tempImageView} alt="Preivew" width="100px"></img> : '' }
                          </div>
                          <div>
                            <label class="labelForm">Provider Name:</label>
                            <input
                              type="text"
                              placeholder="Company Name"
                              onChange={(e) => setName(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Status:</label>
                            <select
                              className="inputForm form-control"
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="">Choose Status</option>
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" 
                     onClick={() => {
                      handleClose(); 
                      tempImageView = '';
                    }}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        CreateProvider();
                        tempImageView = '';
                      }}
                    >
                      Create
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Add Modal End */}

                {/* Add pricing Modal Start */}
                <Modal show={showPricing} onHide={handleClosePricing}>
                  <Modal.Header closeButton>
                    <Modal.Title>New Pricing</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div class="modal-body">
                      <Form className="needs-validation create-form" autocomplete="off" noValidate>
                        <div className="create-form">
                          <div>
                            <label class="labelForm">Provider:</label>
                            <select
                              className="inputForm form-control"
                              onChange={(e) => setProviderId(e.target.value)}
                            >
                              <option value="">Choose Provider</option>
                              {provider &&
                                provider.length > 0 &&
                                provider.map((provider) => (
                                  <option value={provider.id}>
                                    {provider.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div>
                            <label class="labelForm">EV AC (Kwh)</label>
                            <input
                              type="text"
                              placeholder="EV AC"
                              onChange={(e) => setAc(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV AC Price</label>
                            <input
                              type="text"
                              placeholder="EV AC Price"
                              onChange={(e) => setAcPrice(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV DC (Kwh)</label>
                            <input
                              type="text"
                              placeholder="EV DC"
                              onChange={(e) => setDc(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV DC Price</label>
                            <input
                              type="text"
                              placeholder="EV DC Price"
                              onChange={(e) => setDcPrice(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Battery Capacity</label>
                            <input
                              type="text"
                              placeholder="Battery Capacity"
                              onChange={(e) => setBattery(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Pricing Date</label>
                            <input
                              type="date"
                              placeholder="Pricing Date"
                              onChange={(e) => setDate(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePricing}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        CreatePricing();
                        handleClosePricing();
                      }}
                    >
                      Create
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Add pricing Modal End */}

                {/* Update Modal Start */}
                <Modal show={showUpdate} 
                onHide={() => {
                  handleCloseUpdate();
                  tempImageView = '';
                  }}>
                  <Modal.Header closeButton>
                    <Modal.Title>Update Provider</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {loadingPrice ?
                    <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :
                    <div class="modal-body">
                      <Form className="needs-validation create-form" autocomplete="off" noValidate>
                        <div>
                          <label class="labelForm">Provider Name:</label>
                          <input
                            type="text"
                            placeholder="Press Title"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="inputForm form-control"
                            required
                          />
                        </div>
                        <div>
                          <label class="labelForm">Status:</label>
                          <select
                            className="inputForm form-control"
                            onChange={(e) => setStatus(e.target.value)}
                            value={status}
                          >
                            <option value="">Choose Status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>
                        <div>
                          <label class="labelForm">Images:</label>
                          <ImageUploader setImageHandler={setImageHandler} imageUrl={imageUrl} /> 
                              {/* {tempImageView != '' ?
                              <img src={tempImageView} alt="Preivew" width="100px"></img> : 
                              <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img>} */}
                              {tempImageView == '' && imageUrl != '' ? <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img> :(tempImageView == '' ?  '' : <img src={tempImageView} alt="Preivew" width="100px"></img> )}
                          {/* <ImageUploader setImageHandler={setImageHandler} item={singlePost.post_images}/> */}
                        </div>
                      </Form>
                    </div>
}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() => {
                        Update(singleProvider.id);
                        tempImageView = '';
                      }}
                    >
                      Update
                    </Button>
                    <Button variant="secondary" 
                     onClick={() => {
                      handleCloseUpdate();
                      tempImageView = '';}}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Update Modal End */}

                {/* Update Modal Start */}
                <Modal show={showPricingUpdate} onHide={priceCloseUpdate}>
                  <Modal.Header closeButton>
                    <Modal.Title>Update Provider</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div class="modal-body">
                      <Form className="needs-validation create-form" autocomplete="off" noValidate>
                        <div>
                          <label class="labelForm">Provider Name<strong> {singleProvider.name}</strong></label>
                          <input
                              type="hidden"
                              value={providerId}
                              onChange={(e) => setProviderId(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                        </div>
                        <div>
                            <label class="labelForm">EV DC (kwh)</label>
                            <input
                              type="text"
                              placeholder="EV DC"
                              value={ac}
                              onChange={(e) => setAc(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                        <div>
                            <label class="labelForm">EV AC Price</label>
                            <input
                              type="text"
                              placeholder="EV AC Price"
                              value={acPrice}
                              onChange={(e) => setAcPrice(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV DC (kwh)</label>
                            <input
                              type="text"
                              placeholder="EV DC"
                              value={dc}
                              onChange={(e) => setDc(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV DC Price</label>
                            <input
                              type="text"
                              placeholder="EV DC Price"
                              value={dcPrice}
                              onChange={(e) => setDcPrice(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Battery Capacity:</label>
                            <input
                              type="text"
                              placeholder="Battery Capacity"
                              value={battery}
                              onChange={(e) => setBattery(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Pricing Date:</label>
                            <input
                              type="date"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                      </Form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() => {
                        updatePricing(singlePrice.id);
                        priceCloseUpdate();
                      }}
                    >
                      Update
                    </Button>
                    <Button variant="secondary" onClick={priceCloseUpdate}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Update Modal End */}

                {/* View Modal Start */}
                <Modal show={showDetail} onHide={handleDetailClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Provider Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {loadingPrice ?
                    <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :
                    <div class="modal-body">
                      <Form className="needs-validation create-form" autocomplete="off" noValidate>
                        <div className="create-form">
                          <div>
                            <div className="press_img_div">
                              <img
                                src={
                                  process.env.REACT_APP_API +
                                  "/" +
                                  singleProvider.logo
                                }
                                width="70%"
                              />
                              &nbsp;
                            </div>
                          </div>
                          <div>
                            <label class="labelForm">
                              <strong>Name: &nbsp;</strong>{" "}
                              {singleProvider.name}
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>Status: &nbsp; </strong>
                              {singleProvider.status == 0
                                ? "Inactive"
                                : "active"}
                            </label>
                          </div>
                        </div>
                      </Form>
                    </div>
                    }
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleDetailClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* View Modal End */}

                {/* DeleteView Modal */}
                <Modal
                    show={showDelete}
                    onHide={handleDeleteClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header style={{backgroundColor: '#d1193e'}} closeButton>
                      <Modal.Title ><span style={{color: '#fff'}}>DELETE</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                     <span style={{color: '#ee3e38'}}> Are you sure? You want to delete this data!!</span>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDeleteClose}>
                        cancel
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          deleteHandle();
                          // handleDeleteClose();
                        }}
                      >
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Delete Modal End */}

                  {/* DeleteView Modal */}
                  <Modal
                    show={showDeletePrice}
                    onHide={handleDeletePriceClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header style={{backgroundColor: '#d1193e'}} closeButton>
                      <Modal.Title ><span style={{color: '#fff'}}>DELETE</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                     <span style={{color: '#ee3e38'}}> Are you sure? You want to delete this data!!</span>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDeletePriceClose}>
                        cancel
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          deleteHandlePrice();
                          // handleDeleteClose();
                        }}
                      >
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Delete Modal End */}

              </div>
            </div>
          </div>
          <div>
            <NavBar />
            <ToastContainer />
          </div>
        </div>
    </div>
  );
};
export default EVCharging;
