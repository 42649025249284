
export const isTokenExpired = () => {
	const exp = localStorage.getItem("session");
	if (!exp) {
	  return true;
	}
	if (exp <= new Date().getTime()) {
	  return true;
	}
	return false;
  };
  
  export const tokenExpiryInMinute = () => {
	const exp = localStorage.getItem("session");
	if (exp) {
	  let val = exp - new Date().getTime();
	  if (val >= 60000) {
		return (val / 1000 / 60).toFixed(0) + " minutes";
	  } else {
		return (val / 1000 / 60).toFixed(0) + " seconds";
	  }
	}
	return "";
  };

  export const getDefaultDateFormat = () => {
	return 'YYYY-MM-DD';
};

export const getParamDate = (datetime) => {
	
	var date = new Date(datetime).format('YYYY-MM-DD');
    return date;
};