import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ToastHelper from "../Partial/toast";
import Header from "../../Layout/Header";
import NavBar from "../../Layout/NavBar";
import ReactLoading from "react-loading";
import 'react-quill/dist/quill.snow.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import ImageUploader from "../Partial/SingleUpload";
import useStateWithCallback from 'use-state-with-callback';

let tempImageView = '';
  const AddEV = () => {

  let cargo_json = {
    cargo_space: []
  };
  let history = useHistory();
  const authToken                         = localStorage.getItem("access_token");

  const [images, setImages]                 = useState([]);
  const [loading, setLoading]               = useState(false);
  const [name, setName]                     = useState('');
  const [iceVehicleData, setIceVehicleData] = useState('');
  const [brand, setBrand]                   = useState('');
  const [value1,setValue1]                  = useState('');
  const [value2,setValue2]                  = useState('');
  const [value3,setValue3]                  = useState('');
  const [model, setModel]                   = useState('');
  const [estimated, setEstimated]           = useState('');
  const [cargo, setCargo]                   = useState('');
  const [mVolumn, setMVolumn]               = useState('');
  const [battery, setBattery]               = useState('');
  const [energy, setEnergy]                 = useState('');
  const [speed, setSpeed]                   = useState('');
  const [cost, setCost]                     = useState('');
  const [attachment, setAttachment]         = useState('');
  const [youtube, setYoutube]               = useState('');
  const [ice, setICE]                       = useState('');
  const [maxPower, setMaxPower]             = useState('');
  const [acSelected, setACSeletcted]        = useState(false)
  const [acPower, setAcPower]               = useState('')
  const [dcSelected, setDCSeletcted]        = useState(false)
  const [dcPower, setDcPower]               = useState('')
  const MAX_LENGTH = 200;

  const getIceVehicle= () => {

     let url = `${process.env.REACT_APP_API}/api/ice_vehicles`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        setIceVehicleData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getIceVehicle();
  }, []);

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };


  const CargoHandler = (val1, val2, val3) => {
    var afterDot1 = 0
    var afterDot2 = 0
    var afterDot3 = 0
    afterDot1 = val1.split('.', 2)[1]
    afterDot2 = val2.split('.', 2)[1] 
    afterDot3 = val3.split('.', 2)[1] 

    if(afterDot1 != null) {
      if(afterDot1.length <= 1) {
        setValue1(val1)
      } else {
        setValue1(parseFloat(val1).toFixed(1))
      }
    } else {
      setValue1(val1)
    }

    if(afterDot2 != null) {
      if(afterDot2.length <= 1) {
        setValue2(val2)
      } else {
        setValue2(parseFloat(val2).toFixed(1))
      }
    } else {
      setValue2(val2)
    }

    if(afterDot3 != null) {
      if(afterDot3.length <= 1) {
        setValue3(val3)
      } else {
        setValue3(parseFloat(val3).toFixed(1))
      }
    } else {
      setValue3(val3)
    }
    
    

    // if (afterDot1 == '' || afterDot1 == null){
      // setValue1(parseFloat(val1).toFixed(1))
    // } else {
    //   const beforeDot1 = val1.split('.', 1)[0]
    //   setValue1(beforeDot1 + '.' + afterDot1.substr(0, 1))
    // }

    // if (afterDot2 == '' || afterDot2 == null){
      // setValue2(parseFloat(val2).toFixed(1))
    // } else {
    //   const beforeDot2 = val2.split('.', 1)[0]
    //   setValue2(beforeDot2 + '.' + afterDot2.substr(0, 1))
    // }

    // if (afterDot3 == '' || afterDot3 == null){
      // setValue3(parseFloat(val3).toFixed(1))
    // } else {
    //   const beforeDot3 = val3.split('.', 1)[0]
    //   setValue3(beforeDot3 + '.' + afterDot3.substr(0, 1))
    // }
    
    cargo_json.cargo_space.push({
        key1: val1,
        key2: val2,
        key3: val3
    });
    setCargo(cargo_json);
    // console.log('cargo', cargo_json);
  }
  // Create ---------------------------------------------------------
  const Add = () => {
    
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
    }

    let charging_array = [];
    if (acSelected && acPower) charging_array.push({"type":"AC", "value":acPower})
    if (dcSelected && dcPower) charging_array.push({"type":"DC", "value":dcPower})

     if(name == '' || brand == '' || model == '' || charging_array.length === 0 || ice == '') {
         if(name == '') {
            ToastHelper.toast("Name is required!", null, 'error');
         }
         if(brand == '') {
            ToastHelper.toast("rental is required!", null, 'error');
         }
         if(model == '') {
            ToastHelper.toast("co2 status is required!", null, 'error');
         }
         if(charging_array.length === 0) {
            ToastHelper.toast("EV Charging Type is required!", null, 'error');
         }
        //  console.log(ice,"ice")
         if(ice == '') {
            ToastHelper.toast("ICE Vehicle is required!", null, 'error');
         }
         return;
     }
    setLoading(true);
    if(images != '') {
    var image_data_url = [];
    // images.map((u) => image_data_url.push(u.data_url));
    image_data_url.push(images)
    } else {
        var image_data_url =  images;
    }
    const payLoad = {
            name : name,
            brand: brand,
            model: model,
            estimated_range: estimated,
            cargo_space: JSON.stringify(cargo),
            battery_capacity: battery,
            energy_consumption: energy,
            m3_volumn: mVolumn ? mVolumn : 0,
            speed: speed,
            max_power: maxPower,
            cost: cost,
            attachment_link: attachment,
            youtube_link: youtube,
            ev_charging_type: JSON.stringify(charging_array),
            ice_vehicles_id: ice,
            ev_vehicle_images: image_data_url
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/ev_vehicles`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        Swal.fire("Created!", "Vehicle has been created.", "success");
        // console.log(response);
        setLoading(false);
        history.push('/ev-vehicle');
      })
      .catch(function (error) {
        setLoading(false);
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
       
      });
  };

  return (
    <>
        <div>
          <div class="wrapper">
            <div class="section">
              <div>
                <Header />
              </div>
              <div class="align-form">
                <div className="card-form">
                      <h4>Create EV Vehicle</h4>
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                          <div>
                            <label class="labelForm">Name</label>
                            <input
                              type="text"
                              placeholder="EV vehicle Name"
                              onChange={(e) => setName(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Brand</label>
                            <input
                              type="text"
                              placeholder="brand"
                              onChange={(e) => setBrand(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Model</label>
                            <input
                              type="text"
                              placeholder="Model"
                              onChange={(e) => setModel(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Estimated Range</label>
                            <input
                              type="text"
                              placeholder="Estimated Range"
                              onChange={(e) => setEstimated(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Cargo Space</label>
                            <Row>
                                <Col>
                            <input
                              type="text"
                              placeholder="12m x 14m x 12m"
                              value={value1}
                              required
                              onChange={(e) => {
                                // setValue1(e.target.value);
                                CargoHandler(e.target.value, value2, value3);
                                }}
                              className="inputForm form-control"
                            /></Col>
                            <Col>
                             <input
                              type="text"
                              required
                              placeholder="12m x 14m x 12m"
                              value={value2}
                              onChange={(e) => {
                                // setValue2(e.target.value);
                                CargoHandler(value1,e.target.value, value3 );
                                }}
                              className="inputForm form-control"
                            /></Col>
                            <Col>
                             <input
                              type="text"
                              required
                              placeholder="12m x 14m x 12m"
                              value={value3}
                              onChange={(e) => {
                                // setValue3(e.target.value);
                                 CargoHandler(value1, value2, e.target.value);
                                }}
                              className="inputForm form-control"
                            /></Col>
                            </Row>
                          </div>
                          <div>
                            <label class="labelForm">Battery Capacity</label>
                            <input
                              type="number"
                              required
                              placeholder="Battery Capacity"
                              onChange={(e) => setBattery(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">m<sup>3</sup> Volumn</label>
                            <input
                              type="number"
                              placeholder="m3 Volumn"
                              onChange={(e) => setMVolumn(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Energy Consumption</label>
                            <input
                              type="number"
                              required
                              placeholder="Energy Consumption"
                              onChange={(e) => setEnergy(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Top Speed</label>
                            <input
                              type="number"
                              required
                              placeholder="Top Speed"
                              onChange={(e) => setSpeed(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Max Power</label>
                            <input
                              type="number"
                              placeholder="Max Power"
                              onChange={(e) => setMaxPower(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Cost</label>
                            <input
                              type="number"
                              required
                              placeholder="cost"
                              onChange={(e) => setCost(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Attachment Link</label>
                            <input
                              type="text"
                              placeholder="Attachment Link"
                              onChange={(e) => setAttachment(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Youtube Link</label>
                            <input
                              type="text"
                              placeholder="Youtube Link"
                              onChange={(e) => setYoutube(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV Charging Type</label>
                            <div>
                            <Row>
                              <Col xs={6}>
                                <span>AC</span>  <br/> <input type="checkbox" required
                                onChange={(e) => setACSeletcted(!acSelected)}
                                checked={acSelected}
                                value={acSelected}
                                /> 
                                <input type="number" className="inputForm form-control"
                                placeholder="kW"
                                disabled={!acSelected}
                                required={acSelected}
                                onChange={e=>{
                                  setAcPower(e.target.value)
                                }}
                                />
                              </Col>
                              <Col xs={6}>
                              <span>DC</span> <br/> <input type="checkbox" required
                              onChange={(e) => setDCSeletcted(!dcSelected)}
                              checked={dcSelected}
                              value={dcSelected}
                              
                              /> 
                              <input type="number" className="inputForm form-control"
                              placeholder="kW"
                              disabled={!dcSelected}
                              required={dcSelected}
                              onChange={e=>{
                                setDcPower(e.target.value)
                              }}
                              />
                              </Col>
                            </Row>
                          </div>
                          
                          </div>
                          <div>
                            <label class="labelForm">ICE Vehicle</label>
                            <div>
                            <select
                              className="inputForm form-control"
                              onChange={(e) => setICE(e.target.value)}
                              value={ice}
                              searchable="Search here.."
                              required
                            >
                                <option value=''>
                                  </option>
                              {iceVehicleData &&
                                iceVehicleData.length > 0 &&
                                iceVehicleData.map((data) => (
                                  <option value={data.id}>
                                    {data.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          </div>
                          <div>
                            <label class="labelForm">Images:</label>
                            <ImageUploader setImageHandler={setImageHandler} />
                            {tempImageView != ''? <img src={tempImageView} alt="Preivew" width="100px"></img> : '' }
                          </div>
                          <Button
                        variant="primary"
                        className="btn-adjust-content mt-3"
                        onClick={() => {
                          Add();
                          tempImageView = '';
                        }}
                        disabled = {loading? true : false}
                      >
                         
                        {loading == false ? 'Submit' : 
                        <div className="btn-adjust-content"> LOADING &nbsp;
                            <ReactLoading
                                type={"cylon"}
                                color={"#FFF"}
                                height='80%'
                                width='40px'
                                />
                             </div> }
                      </Button>
                        </Form>
                      </div>
                      
                </div>
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default AddEV;
