import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  ToggleButtonGroup,
  ToggleButton
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ToastHelper from "../Partial/toast";
import Header from "../../Layout/Header";
import NavBar from "../../Layout/NavBar";
import ReactLoading from "react-loading";
import 'react-quill/dist/quill.snow.css';
import TextEditor from "../Partial/TextEditor";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import ImageUploader from "../Partial/SingleUpload";
import useStateWithCallback from 'use-state-with-callback';

let tempImageView = '';
  const AddEVPrice = () => {

  let history = useHistory();
  const authToken                         = localStorage.getItem("access_token");

  const [images, setImages]             = useState([]);
  const [logo, setLogo]                 = useState();
  const [imageUrl, setImageUrl]         = useState();
  const [name, setName]                 = useState();
  const [providerId, setProviderId]     = useState();
  const [visible, setVisible]           = useState(false);
  const [status, setStatus]             = useState();
  const [price, setPrice]               = useState();
  const [speed, setSpeed]               = useState();
  const [ac, setAc]                     = useState();  
  const [acPrice, setAcPrice]           = useState();
  const [dc, setDc]                     = useState();  
  const [dcPrice, setDcPrice]           = useState();
  const [singlePrice, setSinglePrice]   = useState();
  const [battery, setBattery]           = useState();
  const [date, setDate]                 = useState();
  const [isEdit, setIsEdit]             = useState(false);
  const [provider, setProvider]         = useState([]);
  const [loading, setLoading]           = useState(false);
  const MAX_LENGTH = 200;
  const [singleProvider, setSingleProvider] = useState("");

  const handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);

  };


  useEffect(() => {
    getProvider();
  }, []);

    //GET PROVIDER DATA ---------------------------------------------------
    const getProvider = () => {
      setLoading(true);
      let url = `${process.env.REACT_APP_API}/api/evprovider`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
            setProvider(response.data.data);
            setProviderId(response.data.data.id);
            setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };

  // CREATE PRICING ----------------------------------------------------
  const CreatePricing = () => {
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
    }
    
     if(providerId == '' || ac == '' || acPrice == '' || dc =='' || dcPrice == '' ) {
         if(providerId == '') {
            ToastHelper.toast("Provider is required!", null, 'error');
         }
         if(ac == '') {
            ToastHelper.toast("ac Type is required!", null, 'error');
         }
         if(acPrice == '') {
            ToastHelper.toast("ac Price is required!", null, 'error');
         }
         if(dc == '') {
            ToastHelper.toast("dc is required!", null, 'error');
         }
         if(dcPrice == '') {
            ToastHelper.toast("dc Price is required!", null, 'error');
         }
      //    if(battery == '') {
      //     ToastHelper.toast("battery is required!", null, 'error');
      //  }
         return;
     }
     setLoading(true);
    const payLoad = {
      provider_id: providerId,
      ev_ac: ac,     
      ev_ac_cost: acPrice,
      ev_dc: dc,     
      ev_dc_cost: dcPrice,
      battery_capacity: battery,
      pricing_date: date,
      speed: speed,
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/evprices`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`, 
        },
      })
      .then(function (response) {
        setLoading(false);
        Swal.fire("Created!", "Pricing has been created.", "success");
        // console.log(response);
        history.push('/ev-charging');
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };
  return (
    <>
        <div>
          <div class="wrapper">
            <div class="section">
              <div>
                <Header />
              </div>
              <div class="align-form">
                <div className="card-form">
                      <h4>Create EV Price</h4>
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                         <div className="create-form">
                          <div>
                            <label class="labelForm">Provider:</label>
                            <select
                              className="inputForm form-control"
                              onChange={(e) => setProviderId(e.target.value)}
                            >
                              {loading ?
                                <Row className="d-flex justify-content-center align-items-center">
                                <ReactLoading
                                type={'cylon'}
                                color={'#2398ff '}
                                height={60}
                                width={80}
                              /></Row> :<>
                              <option value="">Choose Provider</option>
                              {provider &&
                                provider.length > 0 &&
                                provider.map((provider) => (
                                  <option value={provider.id}>
                                    {provider.name}
                                  </option>
                                ))}
                                </>}
                            </select>
                          </div>
                          <div>
                            <label class="labelForm">EV AC (kW)</label>
                            <input
                              type="text"
                              placeholder="10 kW"
                              onChange={(e) => setAc(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV AC Price</label>
                            <input
                              type="text"

                              placeholder="5 $"
                              onChange={(e) => setAcPrice(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV DC (kW)</label>
                            <input
                              type="text"
                              placeholder="10 kW"
                              onChange={(e) => setDc(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV DC Price</label>
                            <input
                              type="text"

                              placeholder="3 $"
                              onChange={(e) => setDcPrice(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Speed</label>
                            <input
                              type="text"
                              placeholder="speed"
                              onChange={(e) => setSpeed(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Battery Capacity</label>
                            <input
                              type="text"
                              placeholder="Battery Capacity"
                              onChange={(e) => setBattery(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Pricing Date</label>
                            <input
                              type="date"
                              placeholder="Pricing Date"
                              onChange={(e) => setDate(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                        </div>
                          <Button
                        variant="primary"
                        className="btn-adjust-content mt-3"
                        onClick={() => {
                          CreatePricing();
                        }}
                        disabled = {loading? true : false}
                      >
                         
                        {loading == false ? 'Submit' : 
                        <div className="btn-adjust-content"> LOADING &nbsp;
                            <ReactLoading
                                type={"cylon"}
                                color={"#FFF"}
                                height='80%'
                                width='40px'
                                />
                             </div> }
                      </Button>
                      </Form>
                      </div>
                      
                </div>
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default AddEVPrice;
