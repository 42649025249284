import { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class TextEditor extends Component {
    constructor(props) {
      super(props);  
      this.state = { text: this.props.item }
      this.handleChange = this.handleChange.bind(this)
    }

    handleChange(value) {
        this.setState({ text: value }) 
        this.props.setDescription(this.state.text); 
    }

    modules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }
   
    

    formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]
    render() {
      return (
        <div>
          <ReactQuill theme="snow"
                      modules={this.modules}
                      formats={this.formats} 
                      value={this.state.text}
                      onChange={this.handleChange}
                      withPreview={true}
                      accept="accept=image/*"
                     >
          </ReactQuill>
        </div>
      );
    }
  }
   
  export default TextEditor;