import React, { Component } from "react";
import * as util from "../Helper/Utils"

export default function (ComposedComponent) {
  class Authentication extends Component {
    componentDidMount() {
      this.isAuthCheck();
    }

    isAuthCheck = () => {
      const isAuth = localStorage.getItem("isAuthenticated");
      if (!isAuth || util.isTokenExpired()) {
        this.props.history && this.props.history.push("/login");
      }
      return;
    };

    render() {
      const isAuth = localStorage.getItem("isAuthenticated");
      return <>{isAuth && !util.isTokenExpired() ? <ComposedComponent {...this.props} /> : this.isAuthCheck()}</>;
    }
  }

  return Authentication;
}
