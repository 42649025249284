import React, { useEffect, useState } from "react";
import Login from './Components/Login/Login';
import 'bootstrap/dist/css/bootstrap.css';
import Home from './Components/Page/home';
import Authentication from "./Components/Auth/Authentication";
import ReactLoading from "react-loading";
import Dashboard from "./Components/Sections/Dashboard";
import Press from "./Components/Sections/Press";
import UserManagement from './Components/Sections/UserManagement';
import Settings from './Components/Sections/Settings';
import Service from './Components/Sections/Service';
import Partner from './Components/Sections/Partner';
import EVCharging from "./Components/Sections/EVCharging/EVCharging";
import MobilePlan from "./Components/Sections/mobilePlan";
import "toastr/build/toastr.min.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Carrer from "./Components/Sections/carrer";
import IceVehicle from "./Components/Sections/ICEVehicle/ice_vehicle";
import EvVehicle from "./Components/Sections/EvVehicle/EV_vehicle";
import AddEV from "./Components/Sections/EvVehicle/addEV";
import EditEV from "./Components/Sections/EvVehicle/editEV";
import AddICE from "./Components/Sections/ICEVehicle/addIce";
import EditICE from "./Components/Sections/ICEVehicle/editIce";
import EVDetails from "./Components/Sections/EvVehicle/EVDetails";
import Blog from "./Components/Sections/Blog/Blog";
import AddBlog from "./Components/Sections/Blog/addBlog";
import EditBlog from "./Components/Sections/Blog/editBlog";
import AddEVPrice from "./Components/Sections/EVCharging/addPrice";
import EditPrice from "./Components/Sections/EVCharging/editPrice";



function App(props) {

  return (
    <>
          <React.Fragment>
            <Router>
              <Switch>
                <Route exact path="/"><Redirect to="/home" /></Route>
                <Route exact path="/login" component={Login} />
                <Route exact path="/home" component={Authentication(Dashboard)} />
                <Route exact path="/blog" component={Authentication(Blog)} />
                <Route exact path="/add-blog" component={Authentication(AddBlog)} />
                <Route exact path="/edit-blog/:id" component={Authentication(EditBlog)} />
                <Route exact path="/press" component={Authentication(Press)} />
                <Route exact path="/dashboard" component={Authentication(Dashboard)} />
                <Route exact path="/service" component={Authentication(Service)} />
                <Route exact path="/partner" component={Authentication(Partner)} />
                <Route exact path="/carrer" component={Authentication(Carrer)} />
                <Route exact path="/user" component={Authentication(UserManagement)} />
                <Route exact path="/ev-charging" component={Authentication(EVCharging)} />
                <Route exact path="/add-price" component={Authentication(AddEVPrice)} />
                <Route exact path="/edit-price/:id" component={Authentication(EditPrice)} />
                <Route exact path="/mobile-plan" component={Authentication(MobilePlan)} />
                <Route exact path="/ev-vehicle" component={Authentication(EvVehicle)} />
                <Route exact path="/add-ev-vehicle" component={Authentication(AddEV)} />
                <Route exact path="/edit-ev-vehicle/:id" component={Authentication(EditEV)} />
                <Route exact path="/ev-vehicle-details/:id" component={Authentication(EVDetails)} />
                <Route exact path="/ice-vehicle" component={Authentication(IceVehicle)} />
                <Route exact path="/add-ice-vehicle" component={Authentication(AddICE)} />
                <Route exact path="/edit-ice-vehicle/:id" component={Authentication(EditICE)} />
                <Route exact path="/settings" component={Authentication(Settings)} />
              </Switch>
            </Router>
          </React.Fragment>
    </>
  );
}

export default App;
