import React from 'react';
import { toast } from 'react-toastify';

const ToastHelper = {

    toast: (text = '', icon = null, type = '', autoClose = 2000, layout = null, position = 'top-right') => {
        let options = {
            position: position,
            autoClose: autoClose,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
        };

        if(!layout){
            layout = <div>
                {icon && <i className={'material-icons'}>{icon}</i>}
                <span>{text}</span>
            </div>;
        }

        if(type == 'error') {
            toast.error(layout, options);
        } else if(type == 'success') {
            toast.success(layout, options);
        }else {
            toast(layout, options);
        }
    },

}

export default ToastHelper;