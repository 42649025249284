import react from 'react';

import Header from '../Layout/Header';
import NavBar from '../Layout/NavBar';
const UserManagement = props => {
    return (
        <div class="wrapper">
            <div class="section">
                <div>
                    <Header/>
                </div>
              
                <div class="align-adj">
                </div> 
            </div>
            <div><NavBar/></div>
        </div>
        //
        
    );
}
export default UserManagement;
