import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  ToggleButtonGroup,
  ToggleButton
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ToastHelper from "../Partial/toast";
import Header from "../../Layout/Header";
import NavBar from "../../Layout/NavBar";
import ReactLoading from "react-loading";
import 'react-quill/dist/quill.snow.css';
import TextEditor from "../Partial/TextEditor";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import ImageUploader from "../Partial/SingleUpload";
import useStateWithCallback from 'use-state-with-callback';

let tempImageView = '';
  const EditPrice = (props) => {
    let url_id = (props.match && props.match.params && props.match.params.id && props.match.params.id > 0) ? props.match.params.id : 0;
  let history = useHistory();
  const authToken                         = localStorage.getItem("access_token");

  const [images, setImages]             = useState([]);
  const [logo, setLogo]                 = useState();
  const [imageUrl, setImageUrl]         = useState();
  const [name, setName]                 = useState();
  const [providerId, setProviderId]     = useState();
  const [visible, setVisible]           = useState(false);
  const [status, setStatus]             = useState();
  const [price, setPrice]               = useState();
  const [speed, setSpeed]               = useState();
  const [ac, setAc]                     = useState();  
  const [acPrice, setAcPrice]           = useState();
  const [dc, setDc]                     = useState();  
  const [dcPrice, setDcPrice]           = useState();
  const [singlePrice, setSinglePrice]   = useState();
  const [battery, setBattery]           = useState();
  const [date, setDate]                 = useState();
  const [isEdit, setIsEdit]             = useState(false);
  const [provider, setProvider]         = useState([]);
  const [loading, setLoading]           = useState(false);
  const MAX_LENGTH = 200;
  const [singleProvider, setSingleProvider] = useState("");

  const handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);

  };


  useEffect(() => {
    getProvider();
    evprices();
    getProviderWithId();
  }, []);

    //GET PROVIDER DATA ---------------------------------------------------
    const getProvider = () => {
      let url = `${process.env.REACT_APP_API}/api/evprovider`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
            setProvider(response.data.data);
            setProviderId(response.data.data.id);
        })
        .catch((error) => {
          console.log(error);
        });
    };

     //GET PROVIDER WITH ID DATA ---------------------------------------------------
     const getProviderWithId = () => {
       
      let id = url_id;
      let url = `${process.env.REACT_APP_API}/api/evprovider/${id}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
            setSingleProvider(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    //GET PRICE WITH ID DATA ---------------------------------------------------
  const evprices = () => {
      setLoading(true);
      let id = url_id;
      let url = `${process.env.REACT_APP_API}/api/evprices/${id}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        setSinglePrice(response.data.data);
        setAc(response.data.data.ev_ac);
        setAcPrice(response.data.data.ev_ac_cost);
        setDcPrice(response.data.data.ev_dc_cost);
        setProviderId(response.data.data.provider_id);
        setDc(response.data.data.ev_dc);
        setSpeed(response.data.data.speed);
        setBattery(response.data.data.battery_capacity);
        setDate(response.data.data.pricing_date);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };

  // CREATE PRICING ----------------------------------------------------
  const CreatePricing = () => {
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
    }
    
     if(providerId == '' || ac == '' || acPrice == '' || dc =='' || dcPrice == '' || battery == '') {
         if(providerId == '') {
            ToastHelper.toast("Provider is required!", null, 'error');
         }
         if(ac == '') {
            ToastHelper.toast("ac Type is required!", null, 'error');
         }
         if(acPrice == '') {
            ToastHelper.toast("ac Price is required!", null, 'error');
         }
         if(dc == '') {
            ToastHelper.toast("dc is required!", null, 'error');
         }
         if(dcPrice == '') {
            ToastHelper.toast("dc Price is required!", null, 'error');
         }
         if(battery == '') {
          ToastHelper.toast("battery is required!", null, 'error');
       }
         return;
     }

    let id = url_id;
    const payLoad = {
      provider_id: providerId,
      ev_ac: ac,     
      ev_ac_cost: acPrice,
      ev_dc: dc,     
      ev_dc_cost: dcPrice,
      battery_capacity: battery,
      pricing_date: date,
      speed: speed,
    };
    axios
      .put(`${process.env.REACT_APP_API}/api/evprices/${id}`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        Swal.fire("Created!", "Pricing has been created.", "success");
        // console.log(response);
        history.push('/ev-charging');
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };
  return (
    <>
        <div>
          <div class="wrapper">
            <div class="section">
              <div>
                <Header />
              </div>
              <div class="align-form">
                <div className="card-form">
                      <h4>Edit EV Price</h4>
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                          {loading ?
                          <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :<>
                         <div>
                          <label class="labelForm">Provider Name<strong> {singleProvider.name}</strong></label>
                          <input
                              type="hidden"
                              value={providerId}
                              onChange={(e) => setProviderId(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                        </div>
                        <div>
                            <label class="labelForm">EV DC (kwh)</label>
                            <input
                              type="text"
                              placeholder="EV DC"
                              value={ac}
                              onChange={(e) => setAc(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                        <div>
                            <label class="labelForm">EV AC Price</label>
                            <input
                              type="text"
                              placeholder="EV AC Price"
                              value={acPrice}
                              onChange={(e) => setAcPrice(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV DC (kwh)</label>
                            <input
                              type="text"
                              placeholder="EV DC"
                              value={dc}
                              onChange={(e) => setDc(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV DC Price</label>
                            <input
                              type="text"
                              placeholder="EV DC Price"
                              value={dcPrice}
                              onChange={(e) => setDcPrice(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Battery Capacity:</label>
                            <input
                              type="text"
                              placeholder="Battery Capacity"
                              value={battery}
                              onChange={(e) => setBattery(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Pricing Date:</label>
                            <input
                              type="date"
                              value={moment(date).format("YYYY-MM-DD")}
                              onChange={(e) => setDate(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <Button
                        variant="primary"
                        className="btn-adjust-content mt-3"
                        onClick={() => {
                          CreatePricing();
                        }}
                        disabled = {loading? true : false}
                      >
                         
                        {loading == false ? 'Submit' : 
                        <div className="btn-adjust-content"> LOADING &nbsp;
                            <ReactLoading
                                type={"cylon"}
                                color={"#FFF"}
                                height='80%'
                                width='40px'
                                />
                             </div> }
                      </Button>
                      </>}
                      </Form>
                      </div>
                      
                </div>
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default EditPrice;
