import react, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";
import ImageUploader from "./SingleUpload"; 
import ReactLoading from "react-loading";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import toastr from "toastr";
import ToastHelper from "./toast";

let tempImageView = '';
const Logo = (props) => {
  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleDetailClose = () => setShowDetail(false);
  const handleDetailShow = () => setShowDetail(true);
  const handleCloseUpdate = () => setShowUpdate(false);
  const handleShowUpdate = () => setShowUpdate(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [imageUrl, setImageUrl]       = useState();
  const authToken = localStorage.getItem("access_token");
  const [title, setTitle] = useState();
  const [logo, setLogo] = useState([]);
  const [images, setImages] = useState();

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };

  useEffect(() => {
    getlogos();
  }, []);

   //GET LOGO DATA
   const getlogos = (id = 0) => {
    let url = `${process.env.REACT_APP_API}/api/logos`;
    if (id != 0) {
      url = `${process.env.REACT_APP_API}/api/logos/${id}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        if (id != 0) {
          // setSinglePartner(response.data.data);
        } else {
          setLogo(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CreateLogo = () => {
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
      return;
    }
    
    const payLoad = {
      title: title,
      image_url: images,
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/logos`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        Swal.fire("Created!", "Logo has been created.", "success");
        setShow(false);
        // console.log(response);
        getlogos();
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  //  UPDATE LOGO STATUS
   const Update = (id) => {
    if (!id) {
      return false;
    }
    const payLoad = {
      id : id
    };
    axios
      .put(`${process.env.REACT_APP_API}/api/logos/${id}`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
       getlogos();
        handleCloseUpdate();
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  // DELETE LOGO
  const deleteHandle = (id) => {
    if (!id) {
      return false;
    }
    const body = [`${id}`];
    axios
      .delete(`${process.env.REACT_APP_API}/api/logos/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.code === 200) {
          Swal.fire("Deleted!", "Blog has been deleted.", "success");
          getlogos();
        } else if (response.data.code !== 200) {
          toastr.remove();
          toastr.options.positionClass = "toast-top-right";
          toastr.error(response.data.message);
        }
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  // COLOUM FOR LOGO DATA TABLE
  const [columns] = useState([
    {
      name: "Title",
      // width: "25%",
      minWidth:"150px",
      selector: row => row.title,
      sortable: true,
    },
    {
      name: "Images",
      // width: "25%",
      minWidth:"150px",
      cell: (row) => (
        <>
        <div className="logo-img">
               <img
                 src={process.env.REACT_APP_API + "/" + row.image_url}
                 width="80px"
               />
         </div>
       </>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.published,
      // width: "20%",
      minWidth:"150px",
      sortable: true,
      cell: (row) => (
        <div>
          {row.status == 1 ? (
              <span class="text-success tog-switch"  
              onClick={() => { 
                Update(row.id); 
                getlogos();
              }}>
                <i class="fa fa-toggle-on text-success"></i></span>
            ):(
              <span class="text-success tog-switch"  
              onClick={() => { 
                Update(row.id); 
                getlogos();
              }}>
                <i class="fa fa-toggle-off text-danger"></i></span>
            )}
        </div>
      ),
    },
    {
      name: "Action",
      // width: "25%",
      minWidth:"150px",
      cell: (row) => (
        <div>
          {/* <span
            onClick={() => {
              // loadingProcess();
              Update(row.id);
              // getlogos();
            }}
          >
            <i class="fa fa-edit text-primary"></i>{" "}
          </span>{" "} */}
          &nbsp; &nbsp;
          <span
            onClick={() => {
              // loadingProcess();
              deleteHandle(row.id);
            }}
          >
            <i class="fa fa-trash-o text-danger"></i>{" "}
          </span>
        </div>
      ),
    },
  ]);


  // loading screen
  const [loading, setLoading] = useState(false);
  function loadingProcess() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  return (
    <>
      {loading ? (
        <ReactLoading
          type={"cylon"}
          color={"#2398ff "}
          height={60}
          width={80}
          className="loading"
        />
      ) : (
       
           
              <div class="">
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <button
                          type="button"
                          class="btn btn-info btn-partner"
                          onClick={handleShow}
                        >
                          Create New Logo
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        <DataTable
                          title="Logos"
                          defaultSortFieldId={1}
                          pagination
                          highlightOnHover
                          columns={columns}
                          data={logo}
                          progressPending={loading}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                          ignoreRowClick
                          selectableRows
                          dense
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10, 30, 50, 100]}
                        />
                      </Col>
                    </Row>
                  </Container>

                  {/* Add Modal Start */}
                  <Modal show={show} 
                  onHide={() => {
                    handleClose();
                    tempImageView = '';
                  }} >
                    <Modal.Header closeButton>
                      <Modal.Title>Create New !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                    <div>
                  
                        <label class="labelForm">Title:</label>
                        <input
                              type="text"
                              placeholder="Logo Title"
                              onChange={(e) => setTitle(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                      </div>
                      <div>
                        <label class="labelForm">Image:</label>
                            <ImageUploader setImageHandler={setImageHandler} />
                            {tempImageView != ''? <img src={tempImageView} alt="Preivew" width="100px"></img> : '' }
                      </div>
                      </Form>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary"
                       onClick={() => {
                        handleClose(); 
                        tempImageView = '';
                      }}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={() => {
                           CreateLogo();  
                        tempImageView = '';
                      }}>
                        Create
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Add Modal End */}

                </div>
              </div>
         
      )}
    </>
    //
  );
};

export default Logo;
