import react, { useState, useEffect } from 'react'
import '../../assets/css/style.css'
import { Col, Container, Row, Form } from 'react-bootstrap'
import 'font-awesome/css/font-awesome.min.css'
import { Modal, Button } from 'react-bootstrap'
import ReactLoading from 'react-loading'
import DataTable from 'react-data-table-component'
import Header from '../Layout/Header'
import NavBar from '../Layout/NavBar'
import TextEditor from './Partial/TextEditor'
import ImageUploader from "./Partial/SingleUpload"; 
import { ToastContainer } from 'react-toastify';
import axios from 'axios'
import Swal from 'sweetalert2'
import toastr from 'toastr'
import ToastHelper from "./Partial/toast";

let tempImageView = '';
const MobilePlan = (props) => {
  const [show, setShow]             = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)

  const handleDetailClose           = () => setShowDetail(false)
  const handleDetailShow            = () => setShowDetail(true)
  const handleCloseUpdate           = () => setShowUpdate(false)
  const handleShowUpdate            = () => setShowUpdate(true)
  const handleClose                 = () => setShow(false)
  const handleShow                  = () => setShow(true)
  const authToken                   = localStorage.getItem('access_token')
  const [images, setImages]         = useState([])
  const [name, setName]             = useState()
  const [file, setFile]             = useState()
  const [status, setStatus]         = useState()
  const [imageUrl, setImageUrl]     = useState();
  const [description, setDescription] = useState()
  const [mobilePlan, setMobilePlan] = useState([])
  const [singlePlan, setSinglePlan] = useState('')
  const MAX_LENGTH = 200
  const [loading, setLoading] = useState(false)

  function loadingProcess() {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images)
  }
   
  const setFileHandler = (e) => { 

    var reader = new FileReader();
    reader.onload = function() {
        var pdfFilevalue = reader.result;
        // console.log("answer pdfFilevalue", pdfFilevalue);
        setFile(pdfFilevalue);
      };
      var asd =reader.readAsDataURL(e.target.files[0]);
    //   console.log("file value",file);
       
  }

  useEffect(() => {
    getMobilePlan()
  }, [])

  //GET mobilePlan DATA
  const getMobilePlan = (id = 0) => {
    let url = `${process.env.REACT_APP_API}/api/planner`
    if (id != 0) {
      url = `${process.env.REACT_APP_API}/api/planner/${id}`
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        if (id != 0) {
          setSinglePlan(response.data.data)
          setName(response.data.data.name)
          setImageUrl(response.data.data.logo)
          setDescription(response.data.data.description)
          setStatus(response.data.data.status)
        } else {
          setMobilePlan(response.data.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // CREATE mobilePlan
  const createMobilePlan = () => { 
    var form = document.getElementsByClassName('needs-validation')
    if (form[0].checkValidity() === false) {
      form[0].classList.add('was-validated')
      return
    }
    // var image_data_url = []
    // images.map((u) => image_data_url.push(u.data_url))
    const payLoad = {
      name: name,
      description: description,
      status: status,
      file: file,
      logo: images,
    }
    axios
      .post(`http://127.0.0.1:8000/api/planner`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
          
          if(response != ""){
            // console.log("response _",response.data.success); 
            if(response.data.success == true){
                 Swal.fire('Created!', 'mobilePlan has been created.', 'success');
                 handleClose()
            }else{
            // show err
            }
          }else{
              // show err 
          }
        // setShow(false);
        // console.log(response);
       getMobilePlan()
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      })
  }

  // DELETE PARTNER
  const deleteHandle = (id) => {
    if (!id) {
      return false
    }
    const body = [`${id}`]
    axios
      .delete(`${process.env.REACT_APP_API}/api/planner/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.code === 200) {
          Swal.fire('Deleted!', 'Mobile Plan has been deleted.', 'warning')
          getMobilePlan()
        } else if (response.data.code !== 200) {
          toastr.remove()
          toastr.options.positionClass = 'toast-top-right'
          toastr.error(response.data.message)
        }
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      })
  }

  //UPDATE PRESS -----------------------------------------------------
  const Update = (id) => {
    if (!id) {
      return false
    }

    const payLoad = {
      name: name,
      status: status,
      description: description,
      logo: images,
      file:file,
    }
    axios
      .put(`${process.env.REACT_APP_API}/api/planner/${id}`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        getMobilePlan()
        handleCloseUpdate()
        Swal.fire('Updated!', 'mobilePlan has been Updated.', 'success')
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      })
  }

  //UPDATE MOBILE PLAN -----------------------------------------------
  const updateStatus = (id, status) => {
    if (!id) {
      return false;
    }
    const payLoad = {
      id: id,
      status: status,
    };
    axios
      .put(
        `${process.env.REACT_APP_API}/api/planner/updateStatus/${id}`,
        payLoad,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then(function (response) {
        getMobilePlan();
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  // COLOUM FOR DATA TABLE
  const [columns] = useState([
    {
      name: 'Name',
      // width: '15%',
      minWidth:"100px",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Logo',
      // width: '15%',
      minWidth:"100px",
      cell: (row) => (
        <>
          <div className="partner-img">
            <img
              src={process.env.REACT_APP_API + '/' + row.logo}
              width="80px"
            />
          </div>
        </>
      ),
    },
    {
      name: 'Description',
      selector: (row) => (
        <div
          dangerouslySetInnerHTML={{
            __html: `${row.description.substring(0, MAX_LENGTH)} ...`,
          }}
        />
      ),
      // width: '20%',
      minWidth:"200px",
      sortable: true,
    },
    {
        name: "Status",
        selector: (row) => row.status,
        width: "20%",
        sortable: true,
        cell: (row) => (
          <div>
            {row.status == "1" ? (
              <span
                class="text-success tog-switch"
                onClick={() => {
                  updateStatus(row.id, row.status);
                //   getProvider();
                }}
              >
                <i class="fa fa-toggle-on text-success"></i>
              </span>
            ) : (
              <span
                class="text-success tog-switch"
                onClick={() => {
                  updateStatus(row.id, row.status);
                //   getProvider();
                }}
              >
                <i class="fa fa-toggle-off text-danger"></i>
              </span>
            )}
          </div>
        ),
      },
    {
      name: 'PDF',
      selector: (row) => (
        <>
        <a href={process.env.REACT_APP_API + '/' + row.pdf} class="text-primary" target="_blank">
        <i class="fa fa-file-pdf-o text-primary"></i>{' '} View PDF</a>
        </>
      ),
      // width: '15%',
      minWidth:"100px",
      sortable: true,
    },
    {
      name: 'Action',
      // width: '15%',
      minWidth:"100px",
      cell: (row) => (
        <div>
          <span
            onClick={() => {
              handleDetailShow()
              getMobilePlan(row.id)
            }}
          >
            <i class="fa fa-eye text-secondary"></i>{' '}
          </span>{' '}
          &nbsp;&nbsp;
          <span
            onClick={() => {
              handleShowUpdate()
              loadingProcess()
              getMobilePlan(row.id)
            }}
          >
            <i class="fa fa-edit text-primary"></i>{' '}
          </span>{' '}
          &nbsp;&nbsp;
          <span
            onClick={() => {
              // loadingProcess();
              deleteHandle(row.id)
            }}
          >
            <i class="fa fa-trash-o text-danger"></i>{' '}
          </span>
        </div>
      ),
    },
  ])

  return (
    <div>
      {loading ? (
        <ReactLoading
          type={'cylon'}
          color={'#2398ff '}
          height={60}
          width={80}
          className="loading"
        />
      ) : (
        <div class="wrapper">
          <div class="section">
            <div>
              <Header />
            </div>
            <div class="align-adj">
              <div>
                <Container>
                  <Row>
                    <Col>
                      <button
                        type="button"
                        class="btn btn-info btn-partner"
                        onClick={() => {
                          loadingProcess()
                          handleShow()
                        }}
                      >
                        Create New
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      <DataTable
                        title="Mobile Plan"
                        defaultSortFieldId={1}
                        pagination
                        highlightOnHover
                        columns={columns}
                        data={mobilePlan}
                        progressPending={loading}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                        ignoreRowClick
                        selectableRows
                        dense
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 30, 50, 100]}
                      />
                    </Col>
                  </Row>
                </Container>

                {/* Add Modal Start */}
                <Modal show={show}
                 onHide={() => {
                  handleClose();
                  tempImageView = '';
                }} >
                  <Modal.Header closeButton>
                    <Modal.Title>Create New mobilePlan</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div class="modal-body">
                      <Form className="needs-validation create-form" noValidate>
                        <div className="create-form">
                          <div>
                            <label class="labelForm">Images:</label>
                            <ImageUploader setImageHandler={setImageHandler} />
                            {tempImageView != ''? <img src={tempImageView} alt="Preivew" width="100px"></img> : '' }
                          </div>
                          <div>
                            <label class="labelForm">mobilePlan:</label>
                            <input
                              type="text"
                              placeholder="mobilePlan Name"
                              onChange={(e) => setName(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Description:</label>
                            <TextEditor
                              setDescription={setDescription}
                              item={''}
                              className="text-editor"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Status:</label>
                            <select
                              className="inputForm form-control"
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="">Choose Status</option>
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                          </div>
                          <div>
                            <label class="labelForm">PDF Upload:</label>
                            <input
                              type="file" id="pdfFile" accept=".doc,.pdf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              name="fileupload"  onChange={(e) => setFileHandler(e)}
                            />
                            {/* <div>
                              <Document
                                file="../file/qq.pdf"
                                onLoadSuccess={onDocumentLoadSuccess}
                              >
                                <Page pageNumber={pageNumber} />
                              </Document>
                              <p>
                                Page {pageNumber} of {numPages}
                              </p>
                             
                            </div> */}
                            {/* <img src="../img/business.jpg" /> */}
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" 
                   onClick={() => {
                    handleClose(); 
                    tempImageView = '';
                  }}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        createMobilePlan();
                        tempImageView = '';
                      }}
                    >
                      Create
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Add Modal End */}

                {/* Update Modal Start */}
                <Modal show={showUpdate} 
                  onHide={() => {
                    handleCloseUpdate();
                    tempImageView = '';
                    }}>
                  <Modal.Header closeButton>
                    <Modal.Title>Update mobilePlan</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div class="modal-body">
                      <Form className="needs-validation create-form" noValidate>
                        <div>
                          <label class="labelForm">Name:</label>
                          <input
                            type="text"
                            placeholder="Press Title"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="inputForm form-control"
                            required
                          />
                        </div>
                        <div>
                          <label class="labelForm">Description:</label>
                          <TextEditor
                            setDescription={setDescription}
                            item={description}
                            className="text-editor"
                          />
                        </div>
                        <div>
                          <label class="labelForm">Status:</label>
                          <select
                            className="inputForm form-control"
                            onChange={(e) => setStatus(e.target.value)}
                            value={status}
                          >
                            <option value="">Choose Status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>
                        <div>
                          <label class="labelForm">Images:</label>
                          <ImageUploader setImageHandler={setImageHandler} imageUrl={imageUrl} /> 
                              {/* {tempImageView != '' ?
                              <img src={tempImageView} alt="Preivew" width="100px"></img> : 
                              <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img>} */}
                              {tempImageView == '' && imageUrl != '' ? <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img> :(tempImageView == '' ?  '' : <img src={tempImageView} alt="Preivew" width="100px"></img> )}
                          {/* <ImageUploader setImageHandler={setImageHandler} item={singlePost.post_images}/> */}
                        </div>
                        <div>
                            <label class="labelForm">PDF Upload:</label>
                            <input
                              type="file" id="pdfFile" accept=".doc,.pdf,.docx,application/msword"
                              name="fileupload"
                              onChange={(e) => setFileHandler(e)}
                            />
                          </div>
                      </Form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() => {
                        tempImageView = '';
                        Update(singlePlan.id)
                      }}
                    >
                      Update
                    </Button>
                    <Button variant="secondary" 
                    onClick={() => {
                      handleCloseUpdate();
                      tempImageView = '';}}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Update Modal End */}

                {/* View Modal Start */}
                <Modal show={showDetail} onHide={handleDetailClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>mobilePlan Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div class="modal-body">
                      <Form className="needs-validation create-form" noValidate>
                        <div className="create-form">
                          <div className="press_img_div">
                            <img
                              src={
                                process.env.REACT_APP_API +
                                '/' +
                                singlePlan.logo
                              }
                              width="70%"
                            />
                            &nbsp;
                          </div>
                          <div>
                            <label class="labelForm">mobilePlan:</label>
                            <label>{singlePlan.name}</label>
                          </div>
                          <div>
                            <label class="labelForm">Description:</label>
                            <label>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: singlePlan.description,
                                }}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>Status: &nbsp; </strong>
                              {singlePlan.status == 0
                                ? "Inactive"
                                : "active"}
                            </label>
                          </div>
                          <div>
                         
                              <a href={ process.env.REACT_APP_API +
                                '/' + singlePlan.pdf} target="_blank" className="text-primary"> 
                                <i class="fa fa-file-pdf-o"></i> view PDF</a>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleDetailClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* View Modal End */}
              </div>
            </div>
          </div>
          <div>
            <NavBar />
            <ToastContainer />
          </div>
        </div>
        //
      )}
    </div>
  )
}
export default MobilePlan
