import { Container, Row, Col } from 'react-bootstrap';
import Header from '../Layout/Header';
import NavBar from '../Layout/NavBar';

// const Dashboard  = props => {
//     return (
//         <div>
//             <div class="alignLeft">Dashboard</div>
//             <div class="alignRight">Admin/Dashboard</div>
//         </div>
//     );
// }
const Dashboard = props => {

    return (
        <div class="wrapper">
            <div class="section">
                <div>
                    <Header />
                </div>
                <div class="align-adj">
                </div>
            </div>
            <div><NavBar /></div>
        </div>
    );
}
export default Dashboard;
