import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  ToggleButtonGroup,
  ToggleButton
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ToastHelper from "../Partial/toast";
import Header from "../../Layout/Header";
import NavBar from "../../Layout/NavBar";
import ReactLoading from "react-loading";
import 'react-quill/dist/quill.snow.css';
import TextEditor from "../Partial/TextEditor";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import ImageUploader from "../Partial/SingleUpload";
import useStateWithCallback from 'use-state-with-callback';

let tempImageView = '';
  const EditBlog = (props) => {

  let history = useHistory();
  let url_id = (props.match && props.match.params && props.match.params.id && props.match.params.id > 0) ? props.match.params.id : 0;
  
  const authToken                         = localStorage.getItem("access_token");
  const [value, setValue]                   = useState('internal');
  const handleChangeRadio                   = (val) => setValue(val);
  const [posts, setPosts]                   = useState([]);
  const [categories, setCategories]         = useState([]);
  const [images, setImages]                 = useState("");
  const [singlePost, setSinglePost]         = useState("");
  const [loading, setLoading]               = useState(false);
  const [multiCategory, setMultiCategory]   = useState([]);
  const [title, setTitle]                   = useState();
  const [imageUrl, setImageUrl]             = useState('');
  const [status, setStatus]                 = useState();
  const [description, setDescription]       = useState();
  const [link, setLink]                     = useState('');
  const [type, setType]                     = useState('');
  const [isEdit, setIsEdit]                 = useState(false);
  const [compressedFile, setCompressedFile] = useState([]);
  const MAX_LENGTH = 200;

  const handleChange = (e) => {
    if(e.target.selectedOptions.length > 0 ) {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    setMultiCategory(value);
    }
  };

 //GET ALL CATEGORY ----------------------------------------------
 const getCategory = () => {
  axios
    .get(`${process.env.REACT_APP_API}/api/categories`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then(function (response) {
      setCategories(response.data.data);
    })
    .catch(function (error) {
      let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
    });
};

  useEffect(() => {
    getCategory();
    getPosts();
    tempImageView = '';
  }, [ ]);


  const getPosts = () => {
    setLoading(true);
    let id = url_id;
    let url = `${process.env.REACT_APP_API}/api/posts/${id}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
          setSinglePost(response.data.data);
          setIsEdit(response.data.data.id);
          setTitle(response.data.data.title);
          setLink(response.data.data.link);
          setType(response.data.data.status);
          setImageUrl(response.data.data.post_images[0].image_url)
          setStatus(response.data.data.published);
          setDescription(response.data.data.description);
          if(response.data.data.get_category.length > 0) {
            let id_array =[]
            response.data.data.get_category.forEach(element => {
              id_array.push(element.id)
            })
            setMultiCategory(id_array)
          }
      setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };

  // Create ---------------------------------------------------------
  const Update = () => {
    
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
    }
    
     if(title == '' || value == '' || status == '' || description =='' ) {
         if(title == '') {
            ToastHelper.toast("title is required!", null, 'error');
         }
         if(value == '') {
            ToastHelper.toast("Blog Type is required!", null, 'error');
         }
         if(value == 'external') {
          if(link == '' ) {
            ToastHelper.toast("Link is required for External Blog Type!", null, 'error');
          }
         }
         if(status == '') {
            ToastHelper.toast("status is required!", null, 'error');
         }
         if(description == '') {
            ToastHelper.toast("description is required!", null, 'error');
         }
        //  if(multiCategory == '') {
        //     ToastHelper.toast("Category is required!", null, 'error');
        //  }
         return;
     }
    setLoading(true);
    if(images != '') {
    var image_data_url = [];
    // images.map((u) => image_data_url.push(u.data_url));
    image_data_url.push(images)
    } else {
        var image_data_url =  images;
    }
    let id = url_id;
    const payLoad = {
      title: title,
      link: link,
      status: type,
      published: status,
      description: description,
      categoryID: multiCategory,
      imageID: images,
    };
    axios
      .put(`${process.env.REACT_APP_API}/api/posts/${id}`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        setLink(' ');
        Swal.fire("Updated!", "Blog has been Updated.", "success");
        window.location.href = "/blog";
      })
      .catch(function (error) {
        setLink(' ');
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  return (
    <>
        <div>
          <div class="wrapper">
            <div class="section">
              <div>
                <Header />
              </div>
              <div class="align-form">
                <div className="card-form">
                      <h4>Edit Blog</h4>
                      <div class="modal-body">
                       
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        > {loading ?
                          <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :<>
                           <div>
                          <label class="labelForm">Blog type: {type}</label>
                          <input
                              type="text"
                              placeholder="Blog Type"
                              value={type}
                              className="inputForm form-control"
                              hidden
                            />
                          </div>
                          <div>
                            <label class="labelForm">Title:</label>
                            <input
                              type="text"
                              placeholder="Blog Title"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          {type == "external" ?
                          <div>
                            <label class="labelForm">Link:</label>
                            <input
                              type="text"
                              placeholder="Blog Link"
                              value={link}
                              onChange={(e) => setLink(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div> :
                          '' }
                          <div>
                            <label class="labelForm">Category:</label>
                            <select
                              className="inputForm form-control"
                              value={multiCategory}
                              onChange={handleChange}
                              multiple
                              searchable="Search here.."
                            >
                              {categories &&
                                categories.length > 0 &&
                                categories.map((category) => (
                                  <option value={category.id}>
                                    {category.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div>
                            <label class="labelForm">Description:</label>
                            {singlePost && <TextEditor setDescription={setDescription} item={singlePost.description} className="text-editor"/>}
                          </div>
                          <div>
                            <label class="labelForm">Status:</label>
                            <select
                              className="inputForm form-control"
                              onChange={(e) => setStatus(e.target.value)}
                              value={status}
                            >
                              <option value="">Choose Blog Status</option>
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                          </div>
                          <div>
                            <label class="labelForm">Images:</label>
                            <ImageUploader setImageHandler={setImageHandler} imageUrl={imageUrl} /> 
                              {/* {tempImageView != '' ?
                              <img src={tempImageView} alt="Preivew" width="100px"></img> : 
                              <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img>} */}
                             {tempImageView == '' && imageUrl != '' ? <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img> :(tempImageView == '' ?  '' : <img src={tempImageView} alt="Preivew" width="100px"></img> )}
                          </div>
                          <Button
                        variant="primary"
                        className="btn-adjust-content mt-3"
                        onClick={() => {
                          Update();
                        }}
                        disabled = {loading? true : false}
                      >
                         
                        {loading == false ? 'Submit' : 
                        <div className="btn-adjust-content"> LOADING &nbsp;
                            <ReactLoading
                                type={"cylon"}
                                color={"#FFF"}
                                height='80%'
                                width='40px'
                                />
                             </div> }
                      </Button>
                      </>}
                      </Form>
                   
                      </div>
                      
                </div>
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default EditBlog;
