import react, { useState, useEffect } from "react";
import { Router, Switch, Route, NavLink, Link } from "react-router-dom";
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Row, Col} from "react-bootstrap";
import {
  faHome,
  faBlog,
  faUsers,
  faSlidersH,
  faDesktop,
  faChartPie,
  faHandsHelping,
  faCaretDown,
  faCaretRight,
  faBolt,
  faIdCardAlt,
  faCarBattery,
  faCar,
  faTemperatureLow
} from "@fortawesome/free-solid-svg-icons";

const NavBar = (props) => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  useEffect(() => {
    setShow(JSON.parse(window.localStorage.getItem('show')));
    setShow2(JSON.parse(window.localStorage.getItem('show2')));
  }, []);

  useEffect(() => {
    window.localStorage.setItem('show', show);
    window.localStorage.setItem('show2', show2);
  }, [show, show2]);

  return (
    <div className="sidebar">
      <div className="profile">
        <img src="../img/evfy-logo.png" alt="profile_picture" />
      </div>
      <div className="profileMobile">
        <img src="../img/favicon.png" alt="profile_picture" />
      </div>
      <ul className=" nav-tabs">
        <li className="list-padding">
          <NavLink to="/blog" activeClassName="link-ac">
            <Row>
              <Col sm={4} md={4} lg={4} className="d-flex justify-content-center align-items-center"><FontAwesomeIcon
              icon={faBlog}
              aria-hidden="true"
              className="nav-icon"
            /></Col>
              <Col sm={8} md={8} lg={8}><span className="item">Blog</span></Col>
            </Row>
          </NavLink>

        </li>
        <li className="list-padding">
          <NavLink to="/press" activeClassName="link-ac">
          <Row>
              <Col sm={4} md={4} lg={4} className="d-flex justify-content-center align-items-center "><FontAwesomeIcon
              icon={faBlog}
              aria-hidden="true"
              className="nav-icon"
            /></Col>
              <Col sm={8} md={8} lg={8}><span className="item">Press</span></Col>
            </Row>
          </NavLink>
        </li>
        <li className="list-padding">
          <div className="navFontEnd">
            <div
              className={window.location.pathname == '/ice-vehicle' || window.location.pathname == '/ev-vehicle' ? "link-ac nav-div" : 'nav-div'}
              onClick={() => {
                setShow2(!show2);
              }}
            > <Row>
            <Col sm={4} md={4} lg={4} className="d-flex justify-content-center align-items-center ">
              <FontAwesomeIcon
                icon={faHome}
                aria-hidden="true"
                className="nav-icon"
              /></Col>
               <Col sm={8} md={8} lg={8} className="d-flex justify-content-between pe-4 align-items-center">
              <span className="item">
                Vechicle{" "}
              </span>
             
              <FontAwesomeIcon
                icon={show2 === true ? faCaretRight : faCaretDown}
                aria-hidden="true"
              />
               </Col>
               </Row>
            </div>
          </div>
          <ul className={show2 === true ? "show-nav sub-menu navFontEnd" : "sub-menu navFontEnd"}>
            <li className="list-padding navFontEnd">
              <NavLink to="/ice-vehicle" activeClassName="link-ac">
              <Row>
              <Col sm={4} md={4} lg={4} className="d-flex justify-content-center align-items-center "><FontAwesomeIcon
              icon={faCar}
              aria-hidden="true"
              className="nav-icon"
            /></Col>
              <Col sm={8} md={8} lg={8} ><span className="item">ICE Vehicle</span></Col>
            </Row>
              </NavLink>
            </li>
            <li className="list-padding navFontEnd">
              <NavLink
                to="/ev-vehicle"
                activeClassName="link-ac"
              >
                <Row>
              <Col sm={4} md={4} lg={4} className="d-flex justify-content-center align-items-center"><FontAwesomeIcon
              icon={faCarBattery}
              aria-hidden="true"
              className="nav-icon"
            /></Col>
              <Col sm={8} md={8} lg={8}><span className="item">EV Vehicle</span></Col>
            </Row>
              </NavLink>
            </li>
          </ul>
        </li>

        <li className="list-padding displaybreak ">
          <NavLink to="/ice-vehicle" activeClassName="link-ac">
            <FontAwesomeIcon
              icon={faHandsHelping}
              aria-hidden="true"
              className="nav-icon"
            />
            <span className="item">ICE Vehicle</span>
          </NavLink>
        </li>
        <li className="list-padding displaybreak">
          <NavLink to="/ev-vehicle" activeClassName="link-ac">
            <FontAwesomeIcon
              icon={faHandsHelping}
              aria-hidden="true"
              className="nav-icon"
            />
            <span className="item">EV Vehicle</span>
          </NavLink>
        </li>

        <li className="list-padding">
          <NavLink to="/ev-charging" activeClassName="link-ac">
          <Row>
              <Col sm={4} md={4} lg={4} className="d-flex justify-content-center align-items-center"><FontAwesomeIcon
              icon={faBolt}
              aria-hidden="true"
              className="nav-icon"
            /></Col>
              <Col sm={8} md={8} lg={8}><span className="item">EV Charging</span></Col>
            </Row>
            </NavLink>
        </li>
      
        <li className="list-padding">
          <NavLink to="/carrer" activeClassName="link-ac">
          <Row>
              <Col sm={4} md={4} lg={4} className="d-flex justify-content-center align-items-center"><FontAwesomeIcon
              icon={faIdCardAlt}
              aria-hidden="true"
              className="nav-icon"
            /></Col>
              <Col sm={8} md={8} lg={8}><span className="item">Career</span></Col>
            </Row>
          </NavLink>
        </li>
        <li className="list-padding">
          <div className="navFontEnd">
            <div
              className={window.location.pathname == '/service' || window.location.pathname == '/partner' ? "link-ac nav-div" : 'nav-div'}
              onClick={() => {
                setShow(!show);
              }}
            ><Row>
            <Col sm={4} md={4} lg={4} className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                icon={faHome}
                aria-hidden="true"
                className="nav-icon"
              /></Col>
               <Col sm={8} md={8} lg={8} className="d-flex justify-content-between pe-4 align-items-center">
              <span className="item">
                FontEnd{" "}
              </span>
             
              <FontAwesomeIcon
                icon={show2 === true ? faCaretRight : faCaretDown}
                aria-hidden="true"
              />
               </Col>
               </Row>
            </div>
          </div>
          <ul className={show === true ? "show-nav sub-menu navFontEnd" : " sub-menu navFontEnd"}>
            <li className="list-padding navFontEnd">
              <NavLink to="/service" activeClassName="link-ac">
               <Row>
              <Col sm={4} md={4} lg={4} className="d-flex justify-content-center align-items-center"><FontAwesomeIcon
              icon={faChartPie}
              aria-hidden="true"
              className="nav-icon"
            /></Col>
              <Col sm={8} md={8} lg={8}><span className="item">Services</span></Col>
            </Row>
              </NavLink>
            </li>
            <li className="list-padding navFontEnd">
              <NavLink
                to="/partner"
                activeClassName="link-ac"
              // onClick={showHandle}
              >
               <Row>
              <Col sm={4} md={4} lg={4} className="d-flex justify-content-center align-items-center"><FontAwesomeIcon
              icon={faHandsHelping}
              aria-hidden="true"
              className="nav-icon"
            /></Col>
              <Col sm={8} md={8} lg={8}><span className="item">Partners</span></Col>
            </Row>
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="list-padding displaybreak">
          <NavLink to="/service" activeClassName="link-ac">
            <FontAwesomeIcon
              icon={faChartPie}
              aria-hidden="true"
              className="nav-icon"
            />
            <span className="item">Services</span>
          </NavLink>
        </li>
        <li className="list-padding displaybreak">
          <NavLink to="/partner" activeClassName="link-ac">
            <FontAwesomeIcon
              icon={faChartPie}
              aria-hidden="true"
              className="nav-icon"
            />
            <span className="item">Partners</span>
          </NavLink>
        </li>
        <li className="list-padding">
          <NavLink to="/settings" activeClassName="link-ac">
          <Row>
              <Col sm={4} md={4} lg={4} className="d-flex justify-content-center align-items-center"><FontAwesomeIcon
              icon={faSlidersH}
              aria-hidden="true"
              className="nav-icon"
            /></Col>
              <Col sm={8} md={8} lg={8}><span className="item">Settings</span></Col>
            </Row>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
export default NavBar;



 {/* <li className="list-padding">
          <NavLink exact to="/dashboard" activeClassName="link-ac">
            <FontAwesomeIcon
              icon={faHome}
              aria-hidden="true"
              className="nav-icon"
            />
            <span className="item">Dashboard</span>
          </NavLink>
        </li> */}
  {/* <li className="list-padding">
          <NavLink to="/mobile-plan" activeClassName="link-ac">
            <FontAwesomeIcon
              icon={faBolt}
              aria-hidden="true"
              className="nav-icon"
            />
            <span className="item">Mobile Plan</span>
          </NavLink>
        </li> */}
        {/* <li className="list-padding">
          <NavLink to="/user" activeClassName="link-ac">
            <FontAwesomeIcon icon={faUsers} aria-hidden="true" className="nav-icon" />
            <span className="item">Users</span>
          </NavLink>
        </li> */}