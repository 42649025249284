import React, { useState } from "react";
import { Row } from "react-bootstrap";
import Swal from "sweetalert2";
import Compressor from 'compressorjs';

const ImageUploader = (props) => {

  //Image upload
  // const [images, setImages] = useState([]);
  // const [compressedFile, setCompressedFile] = useState([]);

  function blobToDataURL(blob, callback) {
    // console.log("b;pg",blob);
    if(blob.length != 0){
    // console.log("b;pg work" );
      
      var a = new FileReader();
      a.onload = function(e) {callback(e.target.result);}
      a.readAsDataURL(blob);
    }
  }
  const handleCompressedUpload = (e) => {
    // console.log("e",e);
    // console.log("apple");
    const image = e.target.files[0];
    // console.log("image", image.size);
    if(image.size > 3145728 && image.size <= 5242880) {  // greater than 3 MB
      // console.log("image is bigger than 3 MB")
    new Compressor(image, {
      quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
      success(compressedResult){   
          blobToDataURL(compressedResult, function(dataurl){
            props.setImageHandler(dataurl);
     }); 
      },
    });
    } else if(image.size > 5242880 && image.size <= 7340032) {  // greater than 5 MB
      // console.log("image is bigger than 5 MB")

      new Compressor(image, {
        quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
        success(compressedResult){   
            blobToDataURL(compressedResult, function(dataurl){
              props.setImageHandler(dataurl);
       }); 
        },
      });
    } else if(image.size > 7340032 && image.size <= 9437184) { 
      // console.log("image is bigger than 7 MB")

      new Compressor(image, {
        quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
        success(compressedResult){   
            blobToDataURL(compressedResult, function(dataurl){
              props.setImageHandler(dataurl);
       }); 
        },
      });
    } else if(image.size > 9437184 && image.size <= 10485760) { 
      // console.log("image is bigger than 9 MB")
      new Compressor(image, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success(compressedResult){   
            blobToDataURL(compressedResult, function(dataurl){
              props.setImageHandler(dataurl);
       }); 
        },
      });
    } else if(image.size > 10485760) { 
      Swal.fire("ERROR!", "Image size is too big to Upload", "danger");
    } else {
      // console.log("image is lower than 3 MB")
      blobToDataURL(image, function (dataurl) {
        props.setImageHandler(dataurl);
      });
    }
  };
 
  return (
      <input
      className="mt-3"
        accept="image/*,capture=camera"
        capture="”camera"
        type="file"
        onChange={(event) => handleCompressedUpload(event)} />

  );
};

export default ImageUploader;
