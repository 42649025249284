import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import toastr from 'toastr';
import ReactLoading from "react-loading";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [inputField, setInputField] = useState({
    email: "",
    password: ""
  });
  const inputsHandler = (e) => {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.value,
    });
  };

  const loginProcess = () => {
    const { history } = props;
    setLoading(true);
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
      setLoading(false);
      return;
    }
    // CALL API FROM BACKEND
    axios.post(`${process.env.REACT_APP_API}/api/login`, {
      email: inputField.email.toString().toLowerCase(),
      password: inputField.password,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          localStorage.clear();
          setLoading(false);
          localStorage.setItem("access_token", response.data.data.token);
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("user", JSON.stringify(response.data.data.user));
          localStorage.setItem("session", new Date().getTime() + (2 * 60 * 60 * 1000));
          history.push("/blog");
        } else if (response.data.code !== 200) {
          setLoading(false);
          // self.setState({ disable: false });
          toastr.remove();
          toastr.options.positionClass = "toast-top-right";
          toastr.error(response.data.message);
        }
      })
      .catch(function (error) {
        setLoading(false);
        localStorage.clear();
        toastr.remove();
        toastr.options.positionClass = "toast-top-right";
        toastr.error("Email and Password does not match");
        //   self.setState({ disable: false });
      });
  }

  // Enter Key
  function keyHandle(e) {
    var key = e.key;
      if(key == "Enter"){
        // if(inputField == null){
        //   loginProcess();
        // } else {
        //   loadingProcess();
        //   loginProcess();
        // }
        loginProcess();
      }
  }

  return (
    <>
      {
        loading ? (
          <ReactLoading
            type={'cylon'}
            color={'#2398ff '}
            height={80} width={100}
            className="loading"
          />
        ) : (
          <div className="login">
            <Form className="loginForm needs-validation" noValidate>
              <h1 className="text-center"><img src="./img/evfy-logo.png" alt="" className="loginImg" /></h1>
              <FormGroup className="formGroup">
                <div className="paddingStyle">
                  <Label>Email</Label>
                  <Input
                    type="email"
                    name="email"
                    // value={inputField.email}
                     // autoCorrect={true}
                     autoCapitalize="none"
                     autoCompleteType="email"
                    placeholder="Email"
                    onChange={inputsHandler}
                    className="form-control"
                    id="validationCustom01"
                    required
                    onKeyPress={(e) => {
                      keyHandle(e);
                    }}
                  />
                </div>
                <div className="paddingStyle">
                  <Label>Password</Label>
                  <Input
                    type="password"
                    name="password"
                    // value={inputField.password}
                    placeholder="Password"
                    onChange={inputsHandler}
                    className="form-control"
                    id="validationCustom01"
                    required
                    onKeyPress={(e) => {
                      keyHandle(e);
                    }}
                  />
                </div>
                <div className="paddingStyle">
                  {/* <Link to="/Home"><Button className="loginbtn">Log in</Button></Link> */}
                  <Button
                    className="loginbtn"
                    onClick={() => {
                      loginProcess();
                    }}
                    onKeyPress={(e) => {
                      keyHandle(e);
                    }}>
                    Log in
                  </Button>
                </div>
              </FormGroup>
            </Form>
          </div>
        )
      }
    </>

  );
}
export default Login;