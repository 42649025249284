import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  ToggleButtonGroup,
  ToggleButton
} from "react-bootstrap";
import axios from "axios";
import moment from 'moment';
import Swal from "sweetalert2";
import toastr from "toastr";
import Header from "../../Layout/Header";
import NavBar from "../../Layout/NavBar";
import ReactLoading from "react-loading";
import TextEditor from "../Partial/TextEditor";
import ImageUploader from "../Partial/SingleUpload"; 
import 'react-quill/dist/quill.snow.css';
import ToastHelper from "../Partial/toast";
import { ToastContainer } from 'react-toastify';

  let tempImageView = '';
  const Blog = () => {
  const [show, setShow]             = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const [deleteId, setDeleteId]     = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClose           = () =>{
    setShowDelete(false);
    setDeleteId('');
  } 


  const handleDetailClose                   = () => setShowDetail(false);
  const handleDetailShow                    = () => setShowDetail(true);
  const handleCloseUpdate                   = () => setShowUpdate(false);
  const handleShowUpdate                    = () => setShowUpdate(true);
  const handleClose                         = () => setShow(false);
  const handleShow                          = () => setShow(true);

  const [value, setValue]                   = useState('internal');
  const handleChangeRadio                   = (val) => setValue(val);
  const authToken                           = localStorage.getItem("access_token");
  const [posts, setPosts]                   = useState([]);
  const [categories, setCategories]         = useState([]);
  const [images, setImages]                 = useState("");
  const [singlePost, setSinglePost]         = useState("");
  const [loading, setLoading]               = useState(false);
  const [multiCategory, setMultiCategory]   = useState([]);
  const [title, setTitle]                   = useState();
  const [imageUrl, setImageUrl]             = useState('');
  const [status, setStatus]                 = useState();
  const [description, setDescription]       = useState();
  const [link, setLink]                     = useState('');
  const [type, setType]                     = useState('');
  const [isEdit, setIsEdit]                 = useState(false);
  const [compressedFile, setCompressedFile] = useState([]);

  const MAX_LENGTH = 200;

  const handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    setMultiCategory(value);
  };

  useEffect(() => {
    getPosts();
  }, [link]);

  //GET BLOG DATA ----------------------------------------------
  const getPosts = (id = 0) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_API}/api/posts`;
    if (id != 0) {
      url = `${process.env.REACT_APP_API}/api/posts/${id}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        if (id != 0) {
          setSinglePost(response.data.data);
          setIsEdit(response.data.data.id);
          setTitle(response.data.data.title);
          setLink(response.data.data.link);
          setType(response.data.data.status);
          setImageUrl(response.data.data.post_images[0].image_url)
          setStatus(response.data.data.published);
          setDescription(response.data.data.description);

        } else {
          setPosts(response.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };

  // COLOUM FOR DATA TABLE ------------------------------------------
  const [columns] = useState([
    {
      name: "Title",
      width: "100px",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      width: "120px",
      cell: (row) => <div dangerouslySetInnerHTML={{ __html: `${row.description.substring(0, MAX_LENGTH)} ...`}} />,
      sortable: true,
      //format: (row) => `${row.description.substring(0, MAX_LENGTH)} + ...`,
    },
    {
      name: "Category",
      width: "100px",
      cell: (row) => (
        <>
          {row.get_category &&
            row.get_category.length > 0 &&
            row.get_category.map((cat, i) => <span>{cat.name} &nbsp;</span>)}
        </>
      ),
    },
    {
      name: "Images",
      width: "100px",
      cell: (row) => (
        <div className="datatable_img_div">
          {row.post_images &&
            row.post_images.length > 0 &&
            row.post_images.map((img, i) => {
              if(img != undefined ) {
                return <div key={i} className="inside_img_div">
                <img
                  src={process.env.REACT_APP_API + "/" + img.image_url}
                  width="50px"
                />
                 &nbsp;
              </div>
              }
              })}
        </div>
      ),
    },
    {
      name: "Blog Type",
      selector: (row) => row.status,
      width: "100px",
      sortable: true,
      cell: (row) => <>{row.status}</>,
    },
    {
      name: "External Link",
      selector: (row) => row.link,
      width: "100px",
      sortable: true,
      cell: (row) => <> <a href={row.link} target="_blank" ><span style={{color:"#007cde", textDecoration: 'underline'}}>{row.link}</span></a></>,
    },
    {
      name: "Published Date",
      selector: (row) => moment(row.created_at).format('LL'),
      width: "100px",
      sortable: true,
      cell: (row) => <>{moment(row.created_at).format('LL')}</>,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by,
      width: "100px",
      sortable: true,
      cell: (row) => <>{row.created_by}</>,
    },
    {
      name: "Status",
      selector: (row) => row.published,
      width: "100px",
      sortable: true,
      cell: (row) => (
        <div>
          {row.published == 1 ? <span class="text-success">Active</span> : <span class="text-danger">Inactive</span>}
        </div>
      ),
    },
    {
      name: "Action",
      width: "100px",
      cell: (row) => (
        <div>
          <span
            onClick={() => {
              handleDetailShow();
              getPosts(row.id);
            }}
          >
            <i class="fa fa-eye text-secondary"></i>{" "}
          </span>{" "}
          &nbsp;&nbsp;
          <Link to={`/edit-blog/${row.id}`}>
            <i class="fa fa-edit text-primary"></i>{" "}
          </Link>{" "}
          &nbsp;&nbsp;
          <span
            onClick={() => {
              setShowDelete(true);
              setDeleteId(row.id);
            }}
          >
            <i class="fa fa-trash-o text-danger"></i>{" "}
          </span>
        </div>
      ),
    },
  ]);

  // Create ---------------------------------------------------------
  const Add = () => {
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
      return;
    }

    // var image_data_url = [];
    // images.map((u) => image_data_url.push(u.data_url));
    // image_data_url.push(u.data_url)

    const payLoad = {
      title: title,
      link:link,
      status: value,
      published: status,
      description: description,
      categoryID: multiCategory,
      imageID: images,
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/posts`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        Swal.fire("Created!", "Blog has been created.", "success");
        setShow(false);
        setLink(' ');
        getPosts();
      })
      .catch(function (error) {
        setLink(' ');
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  //UPDATE POST -----------------------------------------------------
  const Update = (id) => {

    // alert(images);
    if (!id) {
      return false;
    }
    var image_data_url = [];
    image_data_url = images;
    
    // images.map((u) => image_data_url.push(u.data_url));
    // console.dir("images__",images); 
    // console.dir("image_data_url",image_data_url); 
    
    const payLoad = {
      title: title,
      link: link,
      status: type,
      published: status,
      description: description,
      categoryID: multiCategory,
      imageID: images,
    };
    axios
      .put(`${process.env.REACT_APP_API}/api/posts/${id}`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        getPosts();
        setLink(' ');
        handleCloseUpdate();
        Swal.fire("Updated!", "Blog has been Updated.", "success");
        // window.location.href = "/blog";
      })
      .catch(function (error) {
        setLink(' ');
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  // DELETE POST ----------------------------------------------------
  const deleteHandle = () => {
    if (deleteId == null) {
      return false;
    }
    // console.log("da", deleteId);
    const body = [`${deleteId}`];
    axios
      .delete(`${process.env.REACT_APP_API}/api/posts/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.code === 200) {
          Swal.fire("Deleted!", "Blog has been deleted.", "success");
          getPosts();
          setDeleteId('');
          handleDeleteClose();
        } else if (response.data.code !== 200) {
          toastr.remove();
          toastr.options.positionClass = "toast-top-right";
          toastr.error(response.data.message);
          setDeleteId('');
          handleDeleteClose();
        }
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
        deleteId =  '';
      });
  };

  return (
    <>
        <div>
          <div className="wrapper">
            <div className="section">
              <div>
                <Header />
              </div>
              <div className="align-adj">
                <div>
                  <Container>
                    <Row>
                      <Col>
                      <Link
                          type="button"
                          class="btn btn-info btn-create"
                          to = "/add-blog"
                        >
                          Create New Blog
                        </Link> 
                      </Col>
                    </Row>
                    <Row className="w-100">
                      <Col className="mt-3">
                        <DataTable
                          title="Blog Lists"
                          defaultSortFieldId={1}
                          pagination
                          highlightOnHover
                          columns={columns}
                          progressPending={loading}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                          data={posts}
                          ignoreRowClick
                          selectableRows
                          dense
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10, 30, 50, 100]}
                        />
                      </Col>
                    </Row>
                  </Container>

                  {/* Detail View Modal */}
                  <Modal
                    show={showDetail}
                    onHide={handleDetailClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Detail Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                          <div className="main_img_detail_view">
                          <div className="c">
                            {singlePost.post_images &&
                               singlePost.post_images.length > 0 &&
                               singlePost.post_images.map((img, i) => (
                                <div key={i} className="img_div">
                                <img
                                  src={process.env.REACT_APP_API + "/" + img.image_url}
                                  width="100px"
                                />
                                 &nbsp;
                              </div>
                                ))}
                          </div>
                          </div>
                          <div>
                            <label><strong>{singlePost.title}</strong></label>
                          </div>
                          <div>
                          <label><strong>
                              {singlePost.get_category &&
                                singlePost.get_category.length > 0 &&
                                singlePost.get_category.map((category) => (
                                    <label>{category.name} &nbsp; &nbsp; </label>  
                                ))}</strong>
                           </label> 
                          </div>
                          <div>
                            <label>Status: &nbsp;
                          {singlePost.published ==0? "Inactive" : "active"}</label>
                          </div>
                          <div>
                            <label>Blog Type: &nbsp;
                          {singlePost.status}</label>
                          </div>
                          {singlePost.status == "external"?
                          <div>
                            <label>external link: &nbsp;
                          <a href={singlePost.link} target="_blank" ><span style={{color:"#007cde", textDecoration: 'underline'}}>{singlePost.link}</span></a></label>
                          </div> :
                          ''}
                          <div>
                            <label><div dangerouslySetInnerHTML={{ __html: singlePost.description}} /></label>
                          </div>
                        </Form>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDetailClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Detail View Modal End */}

                     {/* Detail View Modal */}
                     <Modal
                    show={showDelete}
                    onHide={handleDeleteClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header style={{backgroundColor: '#d1193e'}} closeButton>
                      <Modal.Title ><span style={{color: '#fff'}}>DELETE</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                     <span style={{color: '#ee3e38'}}> Are you sure? You want to delete this data!!</span>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDeleteClose}>
                        cancel
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          deleteHandle();
                          // handleDeleteClose();
                        }}
                      >
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Detail View Modal End */}

                </div>
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default Blog;
