import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import { Router, Switch, Route, NavLink, Link } from "react-router-dom";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import toastr from "toastr";
import Header from "../../Layout/Header";
import NavBar from "../../Layout/NavBar";
import ReactLoading from "react-loading";
import ToastHelper from "../Partial/toast";
import { ToastContainer } from 'react-toastify';
import TextEditor from "../Partial/TextEditor";
import ImageUploader from "../Partial/SingleUpload";
import 'react-quill/dist/quill.snow.css';

let tempImageView = '';
  const EvVehicle = () => {
    let cargodata = '';
  const [show, setShow]             = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const [deleteId, setDeleteId]     = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClose           = () =>{
    setShowDelete(false);
    setDeleteId('');
  } 

  const handleDetailClose                 = () => setShowDetail(false);
  const handleDetailShow                  = () => setShowDetail(true);
  const handleCloseUpdate                 = () => setShowUpdate(false);
  const handleShowUpdate                  = () => setShowUpdate(true);
  const handleClose                       = () => setShow(false);
  const handleShow                        = () => setShow(true);
  const authToken                         = localStorage.getItem("access_token");
  const [posts, setPosts]                 = useState([]);
  const [images, setImages]               = useState([]);
  const [singlePost, setSinglePost]       = useState("");
  const [imageUrl, setImageUrl]             = useState();
  const [loading, setLoading]             = useState(false);
  const [title, setTitle]                 = useState();
  const [status, setStatus]               = useState();
  const [description, setDescription]     = useState();
  const [isEdit, setIsEdit]               = useState(false);
  const MAX_LENGTH = 200;

  const handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    // setMultiCategory(value);
  };

  useEffect(() => {
    getEV();
  }, []);

  //GET EV DATA ----------------------------------------------
  const getEV= (id = 0) => {
    setLoading(true);
     let url = `${process.env.REACT_APP_API}/api/ev_vehicles`;
     setLoading(true);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
          setPosts(response.data.data);
          setLoading(false);
          // console.log(response.data.data);
          setLoading(false);
      })
      .catch((error) => {
      //  console.log(error);
        setLoading(false);
        setLoading(false);
      });
  };

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };

  // COLOUM FOR DATA TABLE ------------------------------------------
  const [columns] = useState([
    {
      name: "Name",
      // width: "8%",
      minWidth:"100px",
      selector: (row) => row.name,
      sortable: true,
    },
    {
        name: "Brand",
        // width: "8%",
        minWidth:"100px",
        selector: (row) => row.brand,
        sortable: true,
      },
      {
        name: "Model",
        // width: "8%",
        minWidth:"100px",
        selector: (row) => row.model,
        sortable: true,
      },
      {
        name: "Images",
        // width: "12%",
        minWidth:"120px",
        cell: (row) => (
          <div className="datatable_img_div">
            {row.ev_vehicles_images &&
              row.ev_vehicles_images.map((img, i) => (
                <div key={i} className="inside_img_div">
                  <img
                    src={process.env.REACT_APP_API + "/" + img.img_url}
                    width="50px"
                  />
                   &nbsp;
                </div>
              ))}
          </div>
        ),
      },
      {
        name: "estimated Range",
        // width: "8%",
        minWidth:"100px",
        selector: (row) => row.estimated_range,
        sortable: true,
      },
      {
        name: "Cargo space",
        // width: "8%",
        minWidth:"100px",
        sortable: true,
        cell: (row) => (
            <div>
               {row.cargo_space && row.cargo_space[0] ? 
                JSON.parse(row.cargo_space).cargo_space[0].key1 + ' x ' +
                JSON.parse(row.cargo_space).cargo_space[0].key2 + ' x ' +
                JSON.parse(row.cargo_space).cargo_space[0].key3
              : ""}
            </div>
          ),
      },
      {
        name: "Battery Capacity",
        // width: "8%",
        minWidth:"100px",
        selector: (row) => row.battery_capacity,
        sortable: true,
      },
      {
        name: "Energy Consumption",
        // width: "8%",
        minWidth:"100px",
        selector: (row) => row.energy_consumption,
        sortable: true,
      },
      {
        name: "Speed",
        // width: "8%",
        minWidth:"100px",
        selector: (row) => row.speed,
        sortable: true,
      },
      {
        name: "Cost",
        // width: "8%",
        minWidth:"100px",
        selector: (row) => row.cost,
        sortable: true,
      },
      {
        name: "Attachment Link",
        // width: "8%",
        minWidth:"100px",
        selector: (row) => row.attachment_link,
        sortable: true,
      },
      {
        name: "Youtube Link",
        // width: "8%",
        minWidth:"100px",
        selector: (row) => row.youtube_link,
        sortable: true,
      },
      {
        name: "Charging Type",
        // width: "8%",
        minWidth:"100px",
        // selector: (row) => row.charging_type.name,
        sortable: true,
        cell: (row) => (
          <div>
            {JSON.parse(row.ev_charging_type)[0]?`${JSON.parse(row.ev_charging_type)[0].type} -${JSON.parse(row.ev_charging_type)[0].value}kW`:'' }{JSON.parse(row.ev_charging_type)[1]?`,${JSON.parse(row.ev_charging_type)[1].type} -${JSON.parse(row.ev_charging_type)[1].value}kW`:'' }
            </div>
        )
      },
      {
        name: "m3 Volumn",
        // width: "8%",
        minWidth:"100px",
        // selector: (row) => row.charging_type.name,
        sortable: true,
        cell: (row) => (
          <div> {row.m3_volumn && row.m3_volumn} m<sup>3</sup></div>
        )
      },
      {
        name: "Ice Vehicle",
        // width: "8%",
        minWidth:"100px",
        selector: (row) => row.ice_vehicle && row.ice_vehicle.name,
        sortable: true,
      },
    {
      name: "Action",
      // width: "10%",
      minWidth:"100px",
      cell: (row) => (
        <div>
           <Link to={`/ev-vehicle-details/${row.id}`}>
            <i class="fa fa-eye text-secondary"></i>{" "}
          </Link>{" "}
          &nbsp;&nbsp;
          <Link to={`/edit-ev-vehicle/${row.id}`}>
            <i class="fa fa-edit text-primary"></i>{" "}
          </Link>{" "}
          &nbsp;&nbsp;
          <span
            onClick={() => {
              setShowDelete(true);
              setDeleteId(row.id);
            }}
          >
            <i class="fa fa-trash-o text-danger"></i>{" "}
          </span>
        </div>
      ),
    },
  ]);

  // DELETE PRESS ----------------------------------------------------
  const deleteHandle = () => {
    if (deleteId == '') {
      return false;
    }
    const body = [`${deleteId}`];
    axios
      .delete(`${process.env.REACT_APP_API}/api/ev_vehicles/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.code === 200) {
          Swal.fire("Deleted!", "Press has been deleted.", "success");
          getEV();
          setDeleteId('');
          handleDeleteClose();
        } else if (response.data.code !== 200) {
          toastr.remove();
          toastr.options.positionClass = "toast-top-right";
          toastr.error(response.data.message);
          setDeleteId('');
          handleDeleteClose();
        }
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
        setDeleteId('');
        handleDeleteClose();
      });
  };

  return (
    <>
        <div>
          <div class="wrapper">
            <div class="section">
              <div>
                <Header />
              </div>
              <div class="align-adj">
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <Link
                          type="button"
                          class="btn btn-info btn-create"
                          to = "/add-ev-vehicle"
                        >
                          +  Create New EV Vehicle
                        </Link> 
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3" >
                        <DataTable
                          title="EV Vehicle Lists"
                          // style={{backgroundColor: '#fff'}}
                          defaultSortFieldId={1}
                          pagination
                          highlightOnHover
                          columns={columns}
                          data={posts}
                          progressPending={loading}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                          ignoreRowClick
                          selectableRows
                          dense
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10, 30, 50, 100]}
                        />
                      </Col>
                    </Row>
                  </Container>

                  {/* Detail View Modal */}
                  <Modal
                    show={showDetail}
                    onHide={handleDetailClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Detail Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                           <div>
                                <div className="press_img_div">
                                <img
                                  src={process.env.REACT_APP_API + "/" + singlePost.image_url}
                                  width="70%"
                                />
                                 &nbsp;
                              </div>
                          </div>
                          <div>
                            <label>{singlePost.title}</label>
                          </div>
                          <div>
                            <label><div dangerouslySetInnerHTML={{ __html: singlePost.description}} /></label>
                          </div>
                          <div>
                            <label class="labelForm">Status:</label>
                            <label>{singlePost.published ==0? "Inactive" : "active"}</label>
                          </div>
                        </Form>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDetailClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Detail View Modal End */}

                    {/* Detail View Modal */}
                    <Modal
                    show={showDelete}
                    onHide={handleDeleteClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header style={{backgroundColor: '#d1193e'}} closeButton>
                      <Modal.Title ><span style={{color: '#fff'}}>DELETE</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                     <span style={{color: '#ee3e38'}}> Are you sure? You want to delete this data!!</span>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDeleteClose}>
                        cancel
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          deleteHandle();
                          // handleDeleteClose();
                        }}
                      >
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Detail View Modal End */}
                </div>
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default EvVehicle;
