import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ToastHelper from "../Partial/toast";
import Header from "../../Layout/Header";
import NavBar from "../../Layout/NavBar";
import ReactLoading from "react-loading";
import 'react-quill/dist/quill.snow.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

let tempImageView = '';
  const AddICE = () => {

  let history = useHistory();
  const authToken                         = localStorage.getItem("access_token");

  const [images, setImages]               = useState([]);
  const [loading, setLoading]             = useState(false);
  const [name, setName]                   = useState('');
  const [rental, setRental]               = useState('');
  const [co2, setCo2]                     = useState('');
  const [patrol, setPatrol]               = useState('');
  const [fuel, setFuel]                   = useState('');
  const MAX_LENGTH = 200;

  const handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
  };

  useEffect(() => {
    
  }, []);

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };


  // Create ---------------------------------------------------------
  const Add = () => {
    
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
    }
    
     if(name == '' || rental == '' || co2 == '') {
         if(name == '') {
            ToastHelper.toast("Name is required!", null, 'error');
         }
         if(rental == '') {
            ToastHelper.toast("rental is required!", null, 'error');
         }
         if(co2 == '') {
            ToastHelper.toast("co2 status is required!", null, 'error');
         }
         return;
     }
    setLoading(true);
    const payLoad = {
        name : name,
        rental_price: rental,
        co2_status: co2,
        petrol_capacity: patrol,
        fuel_efficiency: fuel
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/ice_vehicles`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        Swal.fire("Created!", "Vehicle has been created.", "success");
        // console.log(response);
        setLoading(false);
        history.push('/ice-vehicle');
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  return (
    <>
        <div>
          <div class="wrapper">
            <div class="section">
              <div>
                <Header />
              </div>
              <div class="align-form">
                <div className="card-form">
                      <h4>Create ICE Vehicle</h4>
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                          <div>
                            <label class="labelForm">Name</label>
                            <input
                              type="text"
                              placeholder="ice vehicle Name"
                              onChange={(e) => setName(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">rental price</label>
                            <input
                              type="number"
                              placeholder="rental price"
                              onChange={(e) => setRental(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">CO2 status</label>
                            <input
                              type="number"
                              placeholder="CO2 status"
                              onChange={(e) => setCo2(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Petrol Capacity</label>
                            <input
                              type="number"
                              placeholder="Petrol Capacity"
                              onChange={(e) => setPatrol(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Fuel Eficiency</label>
                            <input
                              type="number"
                              placeholder="Fuel Eficiency"
                              onChange={(e) => setFuel(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <Button
                        variant="primary"
                        className="btn-adjust-content mt-3"
                        onClick={() => {
                          Add();
                        }}
                        disabled = {loading? true : false}
                      >
                         
                        {loading == false ? 'Submit' : 
                        <div className="btn-adjust-content"> LOADING &nbsp;
                            <ReactLoading
                                type={"cylon"}
                                color={"#FFF"}
                                height='80%'
                                width='40px'
                                />
                             </div> }
                      </Button>
                        </Form>
                      </div>
                      
                </div>
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default AddICE;
