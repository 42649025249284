import react, { useState, useEffect } from "react";
import "../../assets/css/style.css";
import { Col, Container, Row, Form } from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";
import { Modal, Button } from "react-bootstrap";
import ReactLoading from "react-loading";
import DataTable from "react-data-table-component";
import Header from "../Layout/Header";
import { ToastContainer } from 'react-toastify';
import NavBar from "../Layout/NavBar";
import TextEditor from "./Partial/TextEditor";
import ImageUploader from "./Partial/SingleUpload"; 
import axios from "axios";
import Swal from "sweetalert2";
import toastr from "toastr";
import ToastHelper from "./Partial/toast";

let tempImageView = '';
const Partner = (props) => {
  const [show, setShow]               = useState(false);
  const [showDetail, setShowDetail]   = useState(false);
  const [showUpdate, setShowUpdate]   = useState(false);

  const [deleteId, setDeleteId]     = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClose           = () =>{
    setShowDelete(false);
    setDeleteId('');
  } 


  const handleDetailClose             = () => setShowDetail(false);
  const handleDetailShow              = () => setShowDetail(true);
  const handleCloseUpdate             = () => setShowUpdate(false);
  const handleShowUpdate              = () => setShowUpdate(true);
  const handleClose                   = () => setShow(false);
  const handleShow                    = () => setShow(true);
  const authToken                     = localStorage.getItem("access_token");
  const [images, setImages]           = useState();
  const [name, setName]               = useState();
  const [imageUrl, setImageUrl]       = useState();
  const [description, setDescription] = useState();
  const [service, setService]         = useState([]);
  const [loading, setLoading]         = useState(false);
  const [loadingId, setLoadingId]         = useState(false);
  const [singleService, setSingleService] = useState("");
  const MAX_LENGTH = 200;
  function loadingProcess() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };

  useEffect(() => {
    getServices();
  }, []);

  //GET SERVICE DATA
  const getServices = (id = 0) => {
    
    let url = `${process.env.REACT_APP_API}/api/services`;
    if (id != 0) {
      url = `${process.env.REACT_APP_API}/api/services/${id}`;
      setLoadingId(true);
    } else {
      setLoading(true);
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        if (id != 0) {
          setSingleService(response.data.data);
          setName(response.data.data.name);
          setImageUrl(response.data.data.image_url)
          setDescription(response.data.data.description);
          setLoading(false);
          setLoadingId(false);
        } else {
          setService(response.data.data);
          setLoading(false);
          setLoadingId(false);
        }
        setLoading(false);
        setLoadingId(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setLoadingId(false);
      });
  };

  // CREATE SERVICE
  const CreateService = () => {
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
      return;
    }

    setLoading(true)
    const payLoad = {
      name: name,
      description: description,
      image_url: images,
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/services`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        Swal.fire("Created!", "Service has been created.", "success");
        setShow(false);
        // console.log(response);
        getServices();
       
        setLoading(false);
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  // DELETE PARTNER
  const deleteHandle = () => {
    if (deleteId == '') {
      return false;
    }
    const body = [`${deleteId}`];
    axios
      .delete(`${process.env.REACT_APP_API}/api/services/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.code === 200) {
          Swal.fire("Deleted!", "Blog has been deleted.", "success");
          getServices();
          setDeleteId('');
          handleDeleteClose();
        } else if (response.data.code !== 200) {
          toastr.remove();
          toastr.options.positionClass = "toast-top-right";
          toastr.error(response.data.message);
          setDeleteId('');
          handleDeleteClose();
        }
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
        setDeleteId('');
        handleDeleteClose();
      });
  };

 //UPDATE PRESS -----------------------------------------------------
 const Update = (id) => {

  // alert(images);
  if (!id) {
    return false;
  } 
  setLoading(true);
  const payLoad = {
    name: name,
    description: description,
    image_url: images,
  };
  axios
    .put(`${process.env.REACT_APP_API}/api/services/${id}`, payLoad, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then(function (response) {
      getServices();
      handleCloseUpdate();
      Swal.fire("Updated!", "Service has been Updated.", "success");
      setLoading(false);
    })
    .catch(function (error) {
      let errorData = ' ';
      for (const [key, value] of Object.entries(error.response.data.error)) {
        errorData = `${value}`;
        ToastHelper.toast(errorData, null, 'error');
      }
      setLoading(false);
    });
};

  // COLOUM FOR DATA TABLE
  const [columns] = useState([
    {
      name: "Name",
      // width: "20%",
      minWidth:"150px",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Images",
      // width: "25%",
      minWidth:"150px",
      cell: (row) => (
        <>
          <div className="partner-img">
            <img
              src={process.env.REACT_APP_API + "/" + row.image_url}
              width="80px"
            />
          </div>
        </>
      ),
    },
    {
      name: "Description",
      selector: (row) => <div dangerouslySetInnerHTML={{ __html: `${row.description.substring(0, MAX_LENGTH)} ...`}} />,
      // width: "30%",
      minWidth:"200px",
      sortable: true,
    },
    {
      name: "Action",
      // width: "20%",
      minWidth:"100px",
      cell: (row) => (
        <div>
          <span
            onClick={() => {
              handleDetailShow();
              getServices(row.id);
            }}
          >
            <i class="fa fa-eye text-secondary"></i>{" "}
          </span>{" "}
          &nbsp;&nbsp;
          <span
            onClick={() => {
              handleShowUpdate();
              loadingProcess();
              getServices(row.id);
            }}
          >
            <i class="fa fa-edit text-primary"></i>{" "}
          </span>{" "}
          &nbsp;&nbsp;
          <span
  	onClick={() => {
              setShowDelete(true);
              setDeleteId(row.id);
            }}
          >
            <i class="fa fa-trash-o text-danger"></i>{" "}
          </span>
        </div>
      ),
    },
  ]);

  return (
    <div>
        <div class="wrapper">
          <div class="section">
            <div>
              <Header />
            </div>
            <div class="align-adj">
              <div>
                <Container>
                  <Row>
                    <Col>
                      <button
                        type="button"
                        class="btn btn-info btn-partner"
                        onClick={() => {
                          loadingProcess();
                          handleShow();
                        }}
                      >
                        Create New
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      <DataTable
                        title="Service Lists"
                        defaultSortFieldId={1}
                        pagination
                        highlightOnHover
                        columns={columns}
                        data={service}
                        progressPending={loading}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                        ignoreRowClick
                        selectableRows
                        dense
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 30, 50, 100]}
                      />
                    </Col>
                  </Row>
                </Container>
                {/* Add Modal Start */}
                <Modal show={show} 
                 onHide={() => {
                  handleClose();
                  tempImageView = '';
                }} >
                  <Modal.Header closeButton>
                    <Modal.Title>Create New Service</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div class="modal-body">
                      <Form className="needs-validation create-form" noValidate>
                        <div className="create-form">
                          <div>
                            <label class="labelForm">Images:</label>
                            <ImageUploader setImageHandler={setImageHandler} />
                            {tempImageView != ''? <img src={tempImageView} alt="Preivew" width="100px"></img> : '' }
                          </div>
                          <div>
                            <label class="labelForm">Service:</label>
                            <input
                              type="text"
                              placeholder="Service Name"
                              onChange={(e) => setName(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Description:</label>
                            <TextEditor
                              setDescription={setDescription}
                              item={""}
                              className="text-editor"
                            />
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary"
                    onClick={() => {
                      handleClose(); 
                      tempImageView = '';
                    }}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        CreateService();
                        tempImageView = '';

                      }}
                    >
                      Create
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Add Modal End */}

                {/* Update Modal Start */}
                <Modal show={showUpdate} 
                 onHide={() => {
                  handleCloseUpdate();
                  tempImageView = '';
                  }}>
                  <Modal.Header closeButton>
                    <Modal.Title>Update Service</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {loadingId ?
                    <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :
                    <div class="modal-body">
                      <Form className="needs-validation create-form" noValidate>
                        <div>
                          <label class="labelForm">Title:</label>
                          <input
                            type="text"
                            placeholder="Title"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="inputForm form-control"
                            required
                          />
                        </div>
                        <div>
                          <label class="labelForm">Description:</label>
                          <TextEditor
                            setDescription={setDescription}
                            item={singleService.description}
                            className="text-editor"
                          />
                        </div>
                        <div>
                          <label class="labelForm">Images:</label>
                          <ImageUploader setImageHandler={setImageHandler} imageUrl={imageUrl} /> 
                              {/* {tempImageView != '' ?
                              <img src={tempImageView} alt="Preivew" width="100px"></img> : 
                              <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img>} */}
                              {tempImageView == '' && imageUrl != '' ? <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img> :(tempImageView == '' ?  '' : <img src={tempImageView} alt="Preivew" width="100px"></img> )}
                          {/* <ImageUploader setImageHandler={setImageHandler} item={singlePost.post_images}/> */}
                        </div>
                      </Form>
                    </div>
}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() => {
                        tempImageView = '';
                        Update(singleService.id);
                      }}
                    >
                      Update
                    </Button>
                    <Button variant="secondary" 
                     onClick={() => {
                      handleCloseUpdate();
                      tempImageView = '';}}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Update Modal End */}

                {/* View Modal Start */}
                <Modal show={showDetail} onHide={handleDetailClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Service Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {loadingId ?
                    <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :
                    <div class="modal-body">
                      <Form className="needs-validation create-form" noValidate>
                        <div className="create-form">
                          <div className="press_img_div">
                            <img
                              src={
                                process.env.REACT_APP_API +
                                "/" +
                                singleService.image_url
                              }
                              width="70%"
                            />
                            &nbsp;
                          </div>
                          <div>
                            <label class="labelForm">Service:</label>
                            <label>{singleService.name}</label>
                          </div>
                          <div>
                            <label class="labelForm">Description:</label>
                            <label><div dangerouslySetInnerHTML={{ __html: singleService.description}} /></label>
                          </div>
                        </div>
                      </Form>
                    </div>
}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleDetailClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* View Modal End */}

                {/* DeleteView Modal */}
                <Modal
                    show={showDelete}
                    onHide={handleDeleteClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header style={{backgroundColor: '#d1193e'}} closeButton>
                      <Modal.Title ><span style={{color: '#fff'}}>DELETE</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                     <span style={{color: '#ee3e38'}}> Are you sure? You want to delete this data!!</span>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDeleteClose}>
                        cancel
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          deleteHandle();
                          // handleDeleteClose();
                        }}
                      >
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Delete Modal End */}

              </div>
            </div>
          </div>
          <div>
            <NavBar />
            <ToastContainer />
          </div>
        </div>
    </div>
  );
};
export default Partner;
