
import React, { useState, useEffect } from "react";
import "../../assets/css/style.css";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    NavLink,
    useHistory
} from 'react-router-dom'

const fontStyle = {
    fontSize: '50px',
    fontColor: '#fff'
}

const Header = props => {

    const history = useHistory();
    const logout = () => {
        localStorage.clear();
        history.push("/login");
    };

    useEffect(() => {
        const header = document.getElementById("header");
        const scrollCallBack = window.addEventListener("scroll", () => {
            // if (window.pageYOffset > 100) {
            //     header.classList.add("header-scrolled");
            // } else {
            //     header.classList.remove("header-scrolled");
            // }
        });

        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };

    });

    const [loading, setLoading] = useState(false);
    function loadingProcess() {
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
            }, 2000)
    }
    function menuShowHide() {
        document.querySelector("body").classList.toggle("active");
    }
    return (
                <div className="top_navbar">
                    <div className="hamburger">
                        <a >
                            <i className="fa fa-bars" onClick={menuShowHide}></i>
                        </a>
                    </div>
                    <div className="right-group">
                        <FontAwesomeIcon icon={faUserCircle} aria-hidden="true" className="profileIcon"/>
                        <div className="dropdown">
                            <button className="dropbtn">Admin <i className="fa fa-caret-down iconStyle" aria-hidden="true"></i></button>
                            <div className="dropdown-content">
                                <a href="#">Profile</a>
                                <a href="#">Settings</a>
                                <hr />
                                <a href="#" onClick={logout}>Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
       
        
    )
};
export default Header;