import react, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import Header from "../Layout/Header";
import NavBar from "../Layout/NavBar";
import { ToastContainer } from 'react-toastify';
import ReactLoading from "react-loading";
import DataTable from "react-data-table-component";
import Logo from "./Partial/Logo";
import moment from 'moment';
import ToastHelper from "./Partial/toast";

const Settings = (props) => {
  const [show, setShow] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleDetailClose = () => setShowDetail(false);
  const handleDetailShow = () => setShowDetail(true);
  const handleCloseUpdate = () => setShowUpdate(false);
  const handleShowUpdate = () => setShowUpdate(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseBg = () => setShowBg(false);
  const handleShowBg = () => setShowBg(true);

  // COLOUM FOR DATA TABLE
  const [columns2] = useState([
    {
      name: "Title",
      width: "15%",
      // selector: row => row.title,
      sortable: true,
    },
    {
      name: "Description",
      width: "20%",
      // selector: row => row.description,
      sortable: true,
      format: (row) => `${row.description.slice(0, 200)} ...`,
    },
    {
      name: "Images",
      width: "10%",
      cell: (row) => (
        <>
          {/* {row.post_image > 0 && (
        row.post_image.map((img, i) => (
          <div key={i}>{img}</div>
        ))
      )} */}
        </>
      ),
    },
    {
      name: "Published Date",
      selector: (row) => moment(row.created_at).format('LL'),
      width: "15%",
      sortable: true,
      cell: (row) => <>{/* {row.created_at} */}</>,
    },
    {
      name: "Status",
      selector: (row) => row.published,
      width: "10%",
      sortable: true,
      cell: (row) => (
        <div>
          {/* {row.published == 1 ? (
            <span>Active</span>
          ):(
            <span>Inactive</span>
          )} */}
        </div>
      ),
    },
    {
      name: "Action",
      width: "10%",
      cell: (row) => (
        <div>
          <span
            onClick={() => {
              handleDetailShow();
              // getPosts(row.id)
            }}
          >
            <i class="fa fa-eye ms-text-primary"></i>{" "}
          </span>{" "}
          &nbsp;&nbsp;
          <span
            onClick={() => {
              handleShowUpdate();
              loadingProcess();
              // getPosts(row.id);
            }}
          >
            <i class="fa fa-edit ms-text-primary"></i>{" "}
          </span>{" "}
          &nbsp;&nbsp;
          <span
            onClick={() => {
              loadingProcess();
              // deleteHandle();
            }}
          >
            <i class="fa fa-trash-o ms-text-primary"></i>{" "}
          </span>
        </div>
      ),
    },
  ]);

  // loading screen
  const [loading, setLoading] = useState(false);
  function loadingProcess() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  return (
    <>
      {loading ? (
        <ReactLoading
          type={"cylon"}
          color={"#2398ff "}
          height={60}
          width={80}
          className="loading"
        />
      ) : (
        <div class="wrapper">
          <div class="section">
            <div>
              <Header />
            </div>
            <div class="align-adj cont">
              <div class="">
                <div>
                  <Container>
                  <Logo />
                     <Row>
                      <Col>
                        <button
                          type="button"
                          class="btn btn-bg"
                          onClick={handleShowBg}
                        >
                          Create New Banner
                        </button>
                      </Col>
                    </Row>
                    <Row>
                    <Col className="mt-3">
                        <DataTable
                          title="Banner Images"
                          defaultSortFieldId={1}
                          pagination
                          highlightOnHover
                          columns={columns2}
                          progressPending={loading}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                          ignoreRowClick
                          selectableRows
                          dense
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10, 30, 50, 100]}
                        />
                      </Col>
                    </Row>
                  </Container>

                  {/* Add BG Modal Start */}
                  {/* <Modal show={showBg} onHide={handleCloseBg}>
                    <Modal.Header closeButton>
                      <Modal.Title>Create New background !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <label class="labelForm">Images:</label>
                        <ImageUploader />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseBg}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={handleCloseBg}>
                        Create
                      </Button>
                    </Modal.Footer>
                  </Modal> */}
                  {/* Add BG Modal End */}
                </div>
              </div>
            </div>
          </div>
          <div>
            <NavBar />
            <ToastContainer />
          </div>
        </div>
      )}
    </>
    //
  );
};

export default Settings;
