import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import toastr from "toastr";
import ToastHelper from "../Partial/toast";
import Header from "../../Layout/Header";
import NavBar from "../../Layout/NavBar";
import ReactLoading from "react-loading";
import { ToastContainer } from 'react-toastify';
import TextEditor from "../Partial/TextEditor";
import ImageUploader from "../Partial/SingleUpload";
import { Router, Switch, Route, NavLink, Link } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';

let tempImageView = '';
  const IceVehicle = () => {
  const [show, setShow]             = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const [deleteId, setDeleteId]     = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClose           = () =>{
    setShowDelete(false);
    setDeleteId('');
  } 

  const handleDetailClose                 = () => setShowDetail(false);
  const handleDetailShow                  = () => setShowDetail(true);
  const handleCloseUpdate                 = () => setShowUpdate(false);
  const handleShowUpdate                  = () => setShowUpdate(true);
  const handleClose                       = () => setShow(false);
  const handleShow                        = () => setShow(true);
  const authToken                         = localStorage.getItem("access_token");
  const [posts, setPosts]                 = useState([]);
  const [images, setImages]               = useState([]);
  const [singlePost, setSinglePost]       = useState("");
  const [imageUrl, setImageUrl]             = useState('');
  const [loading, setLoading]             = useState(false);
  const [title, setTitle]                 = useState();
  const [status, setStatus]               = useState();
  const [description, setDescription]     = useState();
  const [isEdit, setIsEdit]               = useState(false);
  const MAX_LENGTH = 200;

  function loadingProcess() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  }

  const handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
  };

  useEffect(() => {
    getIceVehicle();
  }, []);

  //GET ICE VEHICLE DATA ----------------------------------------------
  const getIceVehicle= (id = 0) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_API}/api/ice_vehicles`;
    if (id != 0) {
      url = `${process.env.REACT_APP_API}/api/ice_vehicles/${id}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
          if (id != 0) {
            setSinglePost(response.data.data);
          } else {
            setPosts(response.data.data);
          }
          setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };

  // COLOUM FOR DATA TABLE ------------------------------------------
  const [columns] = useState([
    {
      name: "Name",
      // width: "15%",
      minWidth:"100px",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Rental Price",
      // width: "20%",
      minWidth:"100px",
      cell: (row) => row.rental_price,
      sortable: true,
    },
    {
        name: "CO2 Status",
        // width: "20%",
        minWidth:"100px",
        cell: (row) => row.co2_status,
        sortable: true,
    },
    {
        name: "Petrol Capacity",
        // width: "15%",
        minWidth:"100px",
        cell: (row) => row.petrol_capacity,
        sortable: true,
    },
    {
      name: "Fuel efficiency",
      selector: (row) => row.fuel_efficiency,
      // width: "15%",
      minWidth:"100px",
      sortable: true,
      cell: (row) => <>{row.fuel_efficiency}</>,
    },
    {
      name: "Action",
      // width: "10%",
      minWidth:"100px",
      cell: (row) => (
        <div>
          <span
            onClick={() => {
              loadingProcess();
              handleDetailShow();
              getIceVehicle(row.id);
            }}
          >
            <i class="fa fa-eye text-secondary"></i>{" "}
          </span>{" "}
          &nbsp;&nbsp;
         <Link to={`/edit-ice-vehicle/${row.id}`}>
            <i class="fa fa-edit text-primary"></i>{" "}
          </Link>{" "}
          &nbsp;&nbsp;
          <span
  	        onClick={() => {
              setShowDelete(true);
              setDeleteId(row.id);
            }}
          >
            <i class="fa fa-trash-o text-danger"></i>{" "}
          </span>
        </div>
      ),
    },
  ]);


  // DELETE PRESS ----------------------------------------------------
  const deleteHandle = () => {
    if (deleteId == '') {
      return false;
    }
    const body = [`${deleteId}`];
    axios
      .delete(`${process.env.REACT_APP_API}/api/ice_vehicles/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.code === 200) {
          Swal.fire("Deleted!", "Vehicle has been deleted.", "success");
          getIceVehicle();
          setDeleteId('');
          handleDeleteClose();
        } else if (response.data.code !== 200) {
          toastr.remove();
          toastr.options.positionClass = "toast-top-right";
          toastr.error(response.data.message);
          setDeleteId('');
          handleDeleteClose();
        }
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
        setDeleteId('');
        handleDeleteClose();
      });
  };

  return (
    <>
        <div>
          <div class="wrapper">
            <div class="section">
              <div>
                <Header />
              </div>
              <div class="align-adj">
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <Link
                          type="button"
                          class="btn btn-info btn-create"
                          to = "/add-ice-vehicle"
                        >
                          + Create ICE Vehicle
                        </Link> 
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        <DataTable
                          title="ICE Vehicle"
                          defaultSortFieldId={1}
                          pagination
                          highlightOnHover
                          columns={columns}
                          data={posts}
                          progressPending={loading}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                          ignoreRowClick
                          selectableRows
                          dense
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10, 30, 50, 100]}
                        />
                      </Col>
                    </Row>
                  </Container>

                  {/* Detail View Modal */}
                  <Modal
                    show={showDetail}
                    onHide={handleDetailClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Detail Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {loading ?
                    <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                          <div>
                            <h6>ICE Vehicle Name <label>{singlePost.name}</label></h6>
                            <h6>Rental Price <label>{singlePost.rental_price}</label></h6>
                            <h6>Fuel Efficiency <label>{singlePost.fuel_efficiency}</label></h6>
                            <h6>Co2 Status <label>{singlePost.co2_status}</label></h6>
                            <h6>Rental Price <label>{singlePost.rental_price}</label></h6>
                          </div>
                        </Form>
                      </div>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDetailClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Detail View Modal End */}

                  {/* DeleteView Modal */}
                  <Modal
                    show={showDelete}
                    onHide={handleDeleteClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header style={{backgroundColor: '#d1193e'}} closeButton>
                      <Modal.Title ><span style={{color: '#fff'}}>DELETE</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                     <span style={{color: '#ee3e38'}}> Are you sure? You want to delete this data!!</span>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDeleteClose}>
                        cancel
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          deleteHandle();
                          // handleDeleteClose();
                        }}
                      >
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Delete Modal End */}
                  
                </div>
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default IceVehicle;
