import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ToastHelper from "../Partial/toast";
import Header from "../../Layout/Header";
import NavBar from "../../Layout/NavBar";
import ReactLoading from "react-loading";
import 'react-quill/dist/quill.snow.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import ImageUploader from "../Partial/SingleUpload"; 
let tempImageView = '';
  const EVDetails = (props) => {
  let url_id = (props.match && props.match.params && props.match.params.id && props.match.params.id > 0) ? props.match.params.id : 0;
  let history = useHistory();
  const authToken                         = localStorage.getItem("access_token");

  const [images, setImages]                 = useState("");
  const [imageUrl, setImageUrl]             = useState('');
  const [loading, setLoading]               = useState(false);
  const [name, setName]                     = useState('');
  const [iceVehicleData, setIceVehicleData] = useState('');
  const [brand, setBrand]                   = useState('');
  const [model, setModel]                   = useState('');
  const [estimated, setEstimated]           = useState('');
  const [cargo, setCargo]                   = useState('');
  const [battery, setBattery]               = useState('');
  const [energy, setEnergy]                 = useState('');
  const [speed, setSpeed]                   = useState('');
  const [cost, setCost]                     = useState('');
  const [attachment, setAttachment]         = useState('');
  const [youtube, setYoutube]               = useState('');
  const [evCharging, setEVCharging]         = useState('');
  const [evChargingName, setEVChargingName] = useState('');
  const [ice, setICE]                       = useState('');
  const [iceName, setICEName]               = useState('');
  const MAX_LENGTH = 200;

  const handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
  };

      //GET EV VEHICLE DATA ----------------------------------------------
      const getEvVehicle= ( ) => {
        let id = url_id;
        setLoading(true);
        let url = `${process.env.REACT_APP_API}/api/ev_vehicles/${id}`;
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then((response) => {
              setName(response.data.data.name);
              setBrand(response.data.data.brand);
              setModel(response.data.data.model);
              setEstimated(response.data.data.estimated_range);
              setCargo(JSON.parse(response.data.data.cargo_space));
              setBattery(response.data.data.battery_capacity);
              setEnergy(response.data.data.energy_consumption);
              setSpeed(response.data.data.speed);
              setCost(response.data.data.cost);
              setAttachment(response.data.data.attachment_link);
              setYoutube(response.data.data.youtube_link);
              setEVCharging(response.data.data.ev_charging_type_id);
              setEVChargingName(response.data.data.charging_type.name);
              setICE(response.data.data.ice_vehicles_id);
              setICEName(response.data.data.ice_vehicle.name);
              setImageUrl(response.data.data.ev_vehicles_images[0].img_url);
              setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);

          });
      };

  const getIceVehicle= () => {

     let url = `${process.env.REACT_APP_API}/api/ice_vehicles`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        setIceVehicleData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getIceVehicle();
    getEvVehicle();
  }, []);

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };

  return (
    <>
        <div>
          <div class="wrapper">
            <div class="section">
              <div>
                <Header />
              </div>
              <div class="align-form">
                <div className="card-form">
                <h4>EV Vehicle </h4>
                  {loading ?
                          <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :
                      <Container fluid>
                        <Row className="mt-2 mb-1">
                          <Col className="d-flex"><div className="w-50">Name </div>: {name && name}</Col>
                          <Col className="d-flex"><div className="w-50">Brand </div>: {brand && brand}</Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex"><div className="w-50">Model </div>: {model && model}</Col>
                          <Col className="d-flex"><div className="w-50">Estimated Range</div>: {estimated && estimated}</Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex"><div className="w-50">Cargo              </div>: 
                            {cargo && cargo.cargo_space[0].key1 && cargo.cargo_space[0].key1 + ' x ' +
                            cargo.cargo_space[0].key2 + ' x ' +
                            cargo.cargo_space[0].key3}
                            </Col>
                          <Col className="d-flex"><div className="w-50">Battery            </div>: {battery && battery}</Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex"><div className="w-50">Energy Consumption </div>: {energy && energy}</Col>
                          <Col className="d-flex"><div className="w-50">Speed              </div>: {speed && speed}</Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex"><div className="w-50">Cost               </div>: {cost && cost}</Col>
                          <Col className="d-flex"><div className="w-50">Attachment Link    </div>: <a href={attachment} target="_blank">{attachment && attachment}</a></Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex"><div className="w-50">EV Charging Type   </div>: {evChargingName && evChargingName}</Col>
                          <Col className="d-flex"><div className="w-50">Youtube Link       </div>: <a href={youtube} target="_blank">{youtube && youtube}</a></Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex"><div className="w-50">ICE Vehicle        </div>: {iceName && iceName}</Col>
                          <Col className="d-flex"><div className="w-50">Brand              </div>: {brand && brand}</Col>
                        </Row>                     
                      </Container>
  }
                </div>
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default EVDetails;
