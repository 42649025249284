import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ToastHelper from "../Partial/toast";
import Header from "../../Layout/Header";
import NavBar from "../../Layout/NavBar";
import ReactLoading from "react-loading";
import 'react-quill/dist/quill.snow.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import ImageUploader from "../Partial/SingleUpload"; 
let tempImageView = '';
  const EditEV = (props) => {

  let cargo_json = {
    cargo_space: []
  };    
  let url_id = (props.match && props.match.params && props.match.params.id && props.match.params.id > 0) ? props.match.params.id : 0;
  let history = useHistory();
  const authToken                         = localStorage.getItem("access_token");

  const [images, setImages]                 = useState("");
  const [imageUrl, setImageUrl]             = useState('');
  const [loading, setLoading]               = useState(false);
  const [name, setName]                     = useState('');
  const [iceVehicleData, setIceVehicleData] = useState('');
  const [value1,setValue1]                  = useState('');
  const [value2,setValue2]                  = useState('');
  const [value3,setValue3]                  = useState('');
  const [brand, setBrand]                   = useState('');
  const [model, setModel]                   = useState('');
  const [estimated, setEstimated]           = useState('');
  const [cargo, setCargo]                   = useState('');
  const [battery, setBattery]               = useState('');
  const [energy, setEnergy]                 = useState('');
  const [speed, setSpeed]                   = useState('');
  const [mVolumn, setMVolumn]               = useState('');
  const [cost, setCost]                     = useState('');
  const [attachment, setAttachment]         = useState('');
  const [youtube, setYoutube]               = useState('');
  const [ice, setICE]                       = useState('');
  const [maxPower, setMaxPower]             = useState('');
  const [acSelected, setACSeletcted]        = useState(false)
  const [acPower, setAcPower]               = useState('')
  const [dcSelected, setDCSeletcted]        = useState(false)
  const [dcPower, setDcPower]               = useState('')
  const MAX_LENGTH = 200;

  const handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
  };

  const CargoHandler = (v1, v2, v3) => {

    var afterDot1 = 0
    var afterDot2 = 0
    var afterDot3 = 0
    afterDot1 = v1.split('.', 2)[1]
    afterDot2 = v2.split('.', 2)[1] 
    afterDot3 = v3.split('.', 2)[1] 

    if(afterDot1 != null) {
      if(afterDot1.length <= 1) {
        setValue1(v1)
      } else {
        setValue1(parseFloat(v1).toFixed(1))
      }
    } else {
      setValue1(v1)
    }

    if(afterDot2 != null) {
      if(afterDot2.length <= 1) {
        setValue2(v2)
      } else {
        setValue2(parseFloat(v2).toFixed(1))
      }
    } else {
      setValue2(v2)
    }

    if(afterDot3 != null) {
      if(afterDot3.length <= 1) {
        setValue3(v3)
      } else {
        setValue3(parseFloat(v3).toFixed(1))
      }
    } else {
      setValue3(v3)
    }

    cargo_json.cargo_space.push({
        key1: v1,
        key2: v2,
        key3: v3
    });
    setCargo(cargo_json);
    // console.log('cargo', cargo_json);
  }


      //GET EV VEHICLE DATA ----------------------------------------------
      const getEvVehicle= ( ) => {
        let id = url_id;
        setLoading(true);
        let url = `${process.env.REACT_APP_API}/api/ev_vehicles/${id}`;
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then((response) => {
              setName(response.data.data.name);
              setBrand(response.data.data.brand);
              setModel(response.data.data.model);
              setEstimated(response.data.data.estimated_range);
              let cargo_space = JSON.parse(response.data.data.cargo_space)
              setValue1(parseFloat(cargo_space.cargo_space[0].key1).toFixed(1))
              setValue2(parseFloat(cargo_space.cargo_space[0].key2).toFixed(1))
              setValue3(parseFloat(cargo_space.cargo_space[0].key3).toFixed(1))
              setCargo(cargo_space);
              setBattery(response.data.data.battery_capacity);
              setMVolumn(response.data.data.m3_volumn);
              setEnergy(response.data.data.energy_consumption);
              setSpeed(response.data.data.speed);
              setMaxPower(response.data.data.max_power)
              setCost(response.data.data.cost);
              setAttachment(response.data.data.attachment_link);
              setYoutube(response.data.data.youtube_link);
              let ev_charging_type = JSON.parse(response.data.data.ev_charging_type)
              ev_charging_type.forEach(data=>{
                if(data.type === "AC") {
                  setACSeletcted(true)
                  setAcPower(data.value)
                } else {
                  setDCSeletcted(true)
                  setDcPower(data.value)
                }
              })
              setICE(response.data.data.ice_vehicles_id);
              setImageUrl(response.data.data.ev_vehicles_images && response.data.data.ev_vehicles_images[0].img_url && response.data.data.ev_vehicles_images[0].img_url);
              setLoading(false);
              
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);

          });
      };

  const getIceVehicle= () => {

     let url = `${process.env.REACT_APP_API}/api/ice_vehicles`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        setIceVehicleData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getIceVehicle();
    getEvVehicle();
  }, []);

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };


  // Update ---------------------------------------------------------
  const Update = () => {
      
    let id = url_id;
    
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
    }

    let charging_array = [];
    if (acSelected && acPower) charging_array.push({"type":"AC", "value":acPower})
    if (dcSelected && dcPower) charging_array.push({"type":"DC", "value":dcPower})
    
     if(name == '' || brand == '' || model == '' || charging_array.length == 0 || ice == '') {
         if(name == '') {
            ToastHelper.toast("Name is required!", null, 'error');
         }
         if(brand == '') {
            ToastHelper.toast("rental is required!", null, 'error');
         }
         if(model == '') {
            ToastHelper.toast("co2 status is required!", null, 'error');
         }
         if(charging_array.length == 0) {
            ToastHelper.toast("EV Charging Type is required!", null, 'error');
         }
         if(ice == '') {
            ToastHelper.toast("ICE Vehicle is required!", null, 'error');
         }
         return;
     }
    setLoading(true);
    var image_data_url = [];
    // images.map((u) => image_data_url.push(u.data_url));
    image_data_url.push(images)
let payLoad;
if(image_data_url != '') {
    payLoad = {
            name : name,
            brand: brand,
            model: model,
            estimated_range: estimated,
            cargo_space: JSON.stringify(cargo),
            battery_capacity: battery,
            energy_consumption: energy,
            speed: speed,
            cost: cost,
            m3_volumn: mVolumn,
            attachment_link: attachment,
            youtube_link: youtube,
            ev_charging_type: JSON.stringify(charging_array),
            ice_vehicles_id: ice,
            ev_vehicle_images: image_data_url
    }; 
} else {
    payLoad = {
        name : name,
        brand: brand,
        model: model,
        estimated_range: estimated,
        m3_volumn: mVolumn,
        cargo_space: JSON.stringify(cargo),
        battery_capacity: battery,
        energy_consumption: energy,
        speed: speed,
        max_power: maxPower,
        cost: cost,
        attachment_link: attachment,
        youtube_link: youtube,
        ev_charging_type: JSON.stringify(charging_array),
        ice_vehicles_id: ice
}; 
}
    axios
    .put(`${process.env.REACT_APP_API}/api/ev_vehicles/${id}`, payLoad, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then(function (response) {
      Swal.fire("Updated!", "Vehicle has been Updated.", "success");
        // console.log(response);
        setLoading(false);
        history.push('/ev-vehicle');
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  return (
    <>
        <div>
          <div class="wrapper">
            <div class="section">
              <div>
                <Header />
              </div>
              <div class="align-form">
                <div className="card-form">
                      <h4>Edit EV Vehicle</h4>
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                          {loading ?
                          <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :<>
                          <div>
                            <label class="labelForm">Name</label>
                            <input
                              type="text"
                              placeholder="EV vehicle Name"
                              value={name && name}
                              onChange={(e) => setName(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Brand</label>
                            <input
                              type="text"
                              placeholder="brand"
                              value={brand && brand}
                              onChange={(e) => setBrand(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Model</label>
                            <input
                              type="text"
                              placeholder="Model"
                              value={model && model}
                              onChange={(e) => setModel(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Estimated Range</label>
                            <input
                              type="text"
                              placeholder="Estimated Range"
                              value={estimated && estimated}
                              onChange={(e) => setEstimated(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                          <label class="labelForm">Cargo Space</label>
                            <Row>
                                <Col>
                            <input
                              type="text"
                              placeholder="12m x 14m x 12m"
                              value={value1}
                              onChange={(e) => {
                                setValue1(e.target.value);
                                CargoHandler(e.target.value, value2, value3);
                                }}
                              className="inputForm form-control"
                            /></Col>
                            <Col>
                             <input
                              type="text"
                              placeholder="12m x 14m x 12m"
                              value={value2}
                              onChange={(e) => {
                                setValue2(e.target.value);
                                CargoHandler(value1, e.target.value, value3);
                                }}
                              className="inputForm form-control"
                            /></Col>
                            <Col>
                             <input
                              type="text"
                              placeholder="12m x 14m x 12m"
                              value={value3}
                              onChange={(e) => {
                                setValue3(value3);
                                CargoHandler( value1, value2 ,e.target.value);
                                }}
                              className="inputForm form-control"
                            /></Col>
                            </Row>
                          </div>
                          <div>
                            <label class="labelForm">Battery Capacity</label>
                            <input
                              type="number"
                              placeholder="Battery Capacity"
                              value={battery && battery}
                              onChange={(e) => setBattery(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">m<sup>3</sup> Volumn</label>
                            <input
                              type="number"
                              placeholder="m3 Volumn"
                              value={mVolumn && mVolumn}
                              onChange={(e) => setMVolumn(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Energy Consumption</label>
                            <input
                              type="number"
                              placeholder="Energy Consumption"
                              value={energy && energy}
                              onChange={(e) => setEnergy(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Top Speed</label>
                            <input
                              type="number"
                              placeholder="Top Speed"
                              value={speed && speed}
                              onChange={(e) => setSpeed(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Max Power</label>
                            <input
                              type="number"
                              placeholder="Max Power"
                              value={maxPower}
                              onChange={(e) => setMaxPower(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">cost</label>
                            <input
                              type="number"
                              placeholder="cost"
                              value={cost && cost}
                              onChange={(e) => setCost(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Attachment Link</label>
                            <input
                              type="text"
                              placeholder="Attachment Link"
                              value={attachment && attachment}
                              onChange={(e) => setAttachment(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Youtube Link</label>
                            <input
                              type="text"
                              placeholder="Youtube Link"
                              value={youtube && youtube}
                              onChange={(e) => setYoutube(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">EV Charging Type</label>
                            <div>
                            <Row>
                              <Col xs={6}>
                                <span>AC</span>  <br/> <input type="checkbox" 
                                onChange={(e) => setACSeletcted(!acSelected)}
                                checked={acSelected}
                                value={acSelected}
                                /> 
                                <input type="number" className="inputForm form-control"
                                placeholder="kW"
                                disabled={!acSelected}
                                required={acSelected}
                                value={acPower}
                                onChange={e=>{
                                  setAcPower(e.target.value)
                                }}
                                />
                              </Col>
                              <Col xs={6}>
                              <span>DC</span> <br/> <input type="checkbox" 
                              onChange={(e) => setDCSeletcted(!dcSelected)}
                              checked={dcSelected}
                              value={dcSelected}
                              
                              /> 
                              <input type="number" className="inputForm form-control"
                              placeholder="kW"
                              disabled={!dcSelected}
                              required={dcSelected}
                              value={dcPower}
                              onChange={e=>{
                                setDcPower(e.target.value)
                              }}
                              />
                              </Col>
                            </Row>
                          </div>
                          </div>
                          <div>
                            <label class="labelForm">ICE Vehicle</label>
                            <div>
                            <select
                              className="inputForm form-control"
                              value={ice && ice}
                              onChange={(e) => setICE(e.target.value)}
                              searchable="Search here.."
                              required
                            >
                              {iceVehicleData &&
                                iceVehicleData.length > 0 &&
                                iceVehicleData.map((data) => (
                                  <option value={data.id}>
                                    {data.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          </div>
                          <div>
                            <label class="labelForm">Images:</label>
                            <ImageUploader setImageHandler={setImageHandler} imageUrl={imageUrl} /> 
                              {/* {tempImageView != '' ?
                              <img src={tempImageView} alt="Preivew" width="100px"></img> : 
                              <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img>} */}
                              {tempImageView == '' && imageUrl != '' ? <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img> :(tempImageView == '' ?  '' : <img src={tempImageView} alt="Preivew" width="100px"></img> )}
                          </div>
                          <Button
                        variant="primary"
                        className="btn-adjust-content mt-3"
                        onClick={() => {
                          Update();
                        }}
                        disabled = {loading? true : false}
                      >
                         
                        {loading == false ? 'Submit' : 
                        <div className="btn-adjust-content"> LOADING &nbsp;
                            <ReactLoading
                                type={"cylon"}
                                color={"#FFF"}
                                height='80%'
                                width='40px'
                                />
                             </div> }
                      </Button>
                      </>}
                        </Form>
                      </div>
                      
                </div>
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default EditEV;
